import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { withStyles, Typography, Button, Box } from "@material-ui/core"
import LineItem from "./LineItem"
import TablePagination from "../util/table-pagination-items"
import GlobalSpinner from "../common/GlobalSpinner"
import { FormattedMessage } from "react-intl"
import { styles } from "./styles"
import { useItemsContext } from "../../context/providers/ItemsProvider"
import AddItemModal from "./AddItemModal"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const GACategory = "Items"

const ItemPagePresentation = ({ classes }) => {
    const {
        itemsList,
        getItemList,
        loading,
        itemsCount,
        deleteItem,
        addingItem,
        setAddingItem,
        itemEditId,
        editing,
        setEditing,
    } = useItemsContext()

    const { logGAEvent } = useGAContext()

    useEffect(() => {
        getItemList()
    }, [])

    return (
        <div className={classes?.itemPage__container}>
            {addingItem ? (
                <AddItemModal open={addingItem} setOpen={setAddingItem} />
            ) : null}
            {itemEditId ? (
                <AddItemModal
                    item={itemsList.find(item => item._id === itemEditId)}
                    open={editing}
                    setOpen={setEditing}
                />
            ) : null}
            <Box className={classes?.itemPage__title}>
                <Typography variant="h4">
                    <FormattedMessage
                        id="items__title"
                        defaultMessage="Items"
                    />
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Button
                        onClick={() => {
                            logGAEvent("Items", "Add Item Click")
                            setAddingItem(true)
                        }}
                        variant="contained"
                        color="primary"
                    >
                        <FormattedMessage
                            id="generalTerms__add"
                            defaultMessage="Add"
                        />
                    </Button>
                </Box>
            </Box>
            <TablePagination
                elements={itemsList}
                fetchElements={() => {}}
                pageSize={10}
                showPageSizeOptions={false}
                totalElements={itemsCount}
                noElement={() => (
                    <Box
                        sx={{
                            height: "200px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {loading ? (
                            <GlobalSpinner />
                        ) : (
                            <Typography variant="h6">
                                <FormattedMessage
                                    id="items__welcome"
                                    defaultMessage="Welcome to the items page! You have no items to display."
                                />
                            </Typography>
                        )}
                    </Box>
                )}
                render={items => (
                    <Box>
                        {items.map(item => (
                            <LineItem
                                onDeleteItem={() => deleteItem(item)}
                                item={item}
                                key={item._id}
                            />
                        ))}
                    </Box>
                )}
            />
        </div>
    )
}

ItemPagePresentation.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ItemPagePresentation)
