import React, { useEffect } from "react"
import { Grid, withStyles } from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import DeleteIcon from "@material-ui/icons/Delete"
import { Field, formValues } from "redux-form"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import { join } from "../../../../misc"
import { connect } from "react-redux"
import { itemSearchHash } from "../../../../actions/item"
import { providePackageTypes } from "../../../item/FreightBox-line-item"
import { freightClasses } from "../../../item/constants"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline",
    },
    expansionPanel: {
        width: "100%",
    },
    item__icon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    switchesContainer: {
        paddingLeft: "30px",
    },
    itemsContainer: {
        paddingTop: "15px",
    },
    formWrapper: {
        minHeight: "74px",
    },
})

export const composeHandlingUnitSummary = (
    hu,
    weightUnit,
    intl,
    isUsDimWeightEnabled
) => {
    const { multiCarrierSpacePaceWorkflow } = useFlags()

    const freightClassIsEnable = multiCarrierSpacePaceWorkflow
        ? true
        : !isUsDimWeightEnabled

    const firstItemWeight = hu?.items[0]?.weight
        ? `${hu?.items[0]?.weight}${weightUnit}`
        : ""
    const firstItemDescription = hu?.items[0]?.description
        ? `${hu?.items[0]?.description}`
        : ""
    const firstItemClass =
        hu?.items[0]?.freightClass && freightClassIsEnable
            ? intl.formatMessage(
                  {
                      id: "items.handlingUnit__class",
                      defaultMessage: "Class {class}",
                  },
                  { class: hu?.items[0]?.freightClass }
              )
            : ""
    const huCount = intl.formatMessage(
        {
            id: "items.handlingUnit__huCount",
            defaultMessage: "H/U Count: {count}",
        },
        { count: hu?.count }
    )

    let itemInfo = `${firstItemDescription}${
        firstItemWeight ? `, ${firstItemWeight}` : ""
    }${firstItemClass ? `, ${firstItemClass}` : ""}`

    hu.items.forEach((item, index) => {
        const itemWeight = item.weight ? `${item.weight}${weightUnit}` : ""
        const itemDescription = item.description ? `${item.description}` : ""
        const itemClass =
            item?.freightClass && freightClassIsEnable
                ? intl.formatMessage(
                      {
                          id: "items.handlingUnit__class",
                          defaultMessage: "Class {class}",
                      },
                      { class: item?.freightClass }
                  )
                : ""

        if (index !== 0) {
            itemInfo += `  |  ${itemDescription}${
                itemWeight ? `, ${itemWeight}` : ""
            }${itemClass ? `, ${itemClass}` : ""}`
        }
    })

    const packageTypeLabel = hu?.packageType ? `${hu?.packageType}, ` : ""

    return itemInfo
        ? `${itemInfo}  -  ${packageTypeLabel} ${huCount}`
        : `${packageTypeLabel} ${huCount}`
}

const HandlingUnitContainer = ({
    onRemoveClick,
    classes,
    prefix,
    formValues = {},
    changeField,
    items = [],
    count = 1,
    weightUnit,
    isMultiClass,
    isIndividualHUWeight,
    isIntraMexico,
    isIntraCanada,
    isFreightBox,
    intl,
    lengthUnit,
    totalWeight,
}) => {
    useEffect(() => {
        getHUWeight()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMultiClass])

    const getHUWeight = () => {
        if (isMultiClass) {
            const result = items.reduce((acc, item) => {
                if (item.weight) {
                    return (acc += Number(item?.weight))
                } else return acc
            }, 0)
            const finalResult = isIndividualHUWeight
                ? result
                : Number(result * count)

            changeField(
                `${prefix}.totalHUWeight`,
                finalResult ? finalResult : 0
            )
            return finalResult ? finalResult : 0
        } else {
            const finalResult = parseInt(items[0]?.weight ?? 0, 10)
            return finalResult ? finalResult : 0
        }
    }

    const handlePackageType = value => {
        if (value === "FBXP") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 40)
            changeField(`${prefix}.height`, 38)
        } else if (value === "FBXI") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 40)
            changeField(`${prefix}.height`, 28)
        }
    }

    const updateItemWeight = (newWeight, newCount = count) => {
        const huCountWithDefault = newCount ? newCount : 1
        changeField(
            `${prefix}.items[0].weight`,
            Math.ceil(Number(newWeight / huCountWithDefault))
        )
    }

    return (
        <Grid item container>
            {!isIntraMexico && !isIntraCanada ? (
                <Grid
                    item
                    container
                    xs={isIntraCanada ? 2 : 4}
                    alignContent="flex-start"
                    className={classes.formWrapper}
                >
                    {isFreightBox ? (
                        <Field
                            component={FormSelectAutocomplete}
                            name={`${prefix}.packageType`}
                            label={
                                <FormattedMessage
                                    id="getRates.items__packageType"
                                    defaultMessage="Package Type"
                                />
                            }
                            required
                            options={providePackageTypes(intl)}
                            onChange={(e, value) => handlePackageType(value)}
                        />
                    ) : (
                        <Field
                            component={FormSelectAutocomplete}
                            name={`${prefix}.items[0].freightClass`}
                            label={
                                <FormattedMessage
                                    id="items__freightClass"
                                    defaultMessage="Freight Class"
                                />
                            }
                            options={freightClasses}
                            required
                        />
                    )}
                </Grid>
            ) : null}
            {isIntraMexico || isIntraCanada ? (
                <React.Fragment>
                    <Grid
                        item
                        container
                        xs={2}
                        alignContent="flex-start"
                        className={classes.formWrapper}
                    >
                        <Field
                            component={FormField}
                            name={`${prefix}.length`}
                            label={
                                <FormattedMessage
                                    id="getRates.items__length"
                                    defaultMessage="Length ({lengthUnit})"
                                    values={{
                                        lengthUnit,
                                    }}
                                />
                            }
                            required
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        alignContent="flex-start"
                        className={classes.formWrapper}
                    >
                        <Field
                            component={FormField}
                            name={`${prefix}.width`}
                            label={
                                <FormattedMessage
                                    id="getRates.items__width"
                                    defaultMessage="Width ({lengthUnit})"
                                    values={{
                                        lengthUnit,
                                    }}
                                />
                            }
                            required
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={2}
                        alignContent="flex-start"
                        className={classes.formWrapper}
                    >
                        <Field
                            component={FormField}
                            name={`${prefix}.height`}
                            label={
                                <FormattedMessage
                                    id="getRates.items__height"
                                    defaultMessage="Height ({lengthUnit})"
                                    values={{
                                        lengthUnit,
                                    }}
                                />
                            }
                            required
                        />
                    </Grid>
                </React.Fragment>
            ) : null}

            <Grid
                item
                container
                xs={
                    isIntraCanada && formValues?.handlingUnits?.length !== 1
                        ? 1
                        : 2
                }
                justify="center"
                alignItems="center"
                alignContent="flex-start"
                className={classes.formWrapper}
            >
                <Field
                    component={FormField}
                    name={`${prefix}.count`}
                    label={
                        <FormattedMessage
                            id="getRates.items__huCount"
                            defaultMessage="H/U Count"
                        />
                    }
                    required
                    onChange={(e, newCount) => {
                        updateItemWeight(totalWeight, newCount)
                    }}
                />
            </Grid>
            <Grid
                item
                container
                xs={
                    isIntraCanada
                        ? 2
                        : isIntraMexico &&
                          formValues?.handlingUnits?.length !== 1
                        ? 3
                        : 4
                }
                justify="center"
                alignItems="center"
                alignContent="flex-start"
                className={classes.formWrapper}
            >
                <Field
                    component={FormField}
                    name={`${prefix}.totalWeight`}
                    label={
                        <FormattedMessage
                            id="getRates.items__totalWeight"
                            defaultMessage="Total Weight ({weightUnit})"
                            values={{
                                weightUnit,
                            }}
                        />
                    }
                    required
                    onChange={(e, newTotalWeight) => {
                        updateItemWeight(newTotalWeight, count)
                    }}
                />
            </Grid>
            {isIntraCanada ? (
                <Grid
                    item
                    container
                    xs={2}
                    alignContent="flex-start"
                    className={classes.formWrapper}
                >
                    <Field
                        component={FormSelectAutocomplete}
                        name={`${prefix}.items[0].freightClass`}
                        label={
                            <FormattedMessage
                                id="items__freightClass"
                                defaultMessage="Freight Class"
                            />
                        }
                        options={freightClasses}
                        required
                    />
                </Grid>
            ) : null}
            <Grid
                item
                container
                xs={isIntraCanada || isIntraMexico ? 1 : 2}
                justify="flex-end"
                alignItems="center"
                alignContent="center"
            >
                {formValues?.handlingUnits?.length !== 1 ? (
                    <DeleteIcon
                        onClick={e => onRemoveClick(e)}
                        className={classes.item__icon}
                    />
                ) : null}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (
    state,
    { description, unNumber, origin, originAddress }
) => ({
    itemsDescription:
        state.item.search[itemSearchHash(description, origin, originAddress)],
    itemsUN: state.item.search[itemSearchHash(unNumber, origin, originAddress)],
    searchInProgress: state.item.searchInProgress,
})

const HandlingUnit = formValues(({ prefix }) => ({
    count: join(prefix, "count"),
    totalWeight: join(prefix, "totalWeight"),
    items: join(prefix, "items"),
    packageType: join(prefix, "packageType"),
    length: join(prefix, "length"),
    width: join(prefix, "width"),
    height: join(prefix, "height"),
    isMultiClass: join(prefix, "isMultiClass"),
    totalHUWeight: join(prefix, "totalHUWeight"),
    isIndividualHUWeight: join(prefix, "isIndividualHUWeight"),
    hu: prefix,
}))(connect(mapStateToProps)(injectIntl(HandlingUnitContainer)))

export default injectIntl(withStyles(styles)(HandlingUnit))
