import React, { forwardRef, useState } from "react"
import { Button, Grid, Typography, withStyles } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FormSelectBillTo from "../form/form-select-bill-to"
import { change, Field } from "redux-form"
import { modeOptions } from "../redesign/bookShipment/atoms/modeOptions"
import FormRadio from "../form/form-radio"
import { useDispatch } from "react-redux"
import { useOrdersContext } from "../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import GlobalSpinner from "../common/GlobalSpinner"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: 0,
        bottom: 0,
        width: "900px",
        minHeight: "300px",
        height: "fit-content",
        maxHeight: "700px",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
    },
    section: {
        paddingTop: "10px",
    },
    buttonContainer: {
        flexGrow: 1,
    },
    mainContainer: {
        minHeight: "300px",
    },
    errorsContainer: {
        maxHeight: "400px",
        overflowY: "scroll",
        paddingTop: "10px",
    },
    buttonGroup: {
        display: "flex",
        flexDirection: "row",
    },
    documentButton: {
        margin: "0 3px",
    },
    errorEntry: {
        backgroundColor: theme.palette.error.dark,
        color: "white",
        padding: "4px 8px",
        marginBottom: "8px",
        fontWeight: 500,
        borderRadius: "4px",
    },
    causeMessage: {
        whiteSpace: "pre-wrap",
    },
})

const ImportModal = forwardRef((props, ref) => {
    const {
        setModalOpen,
        classes,
        formattedLocations,
        locations,
        formValues,
        language,
        logGAEvent,
        setImportMappingModal,
        gaCategory,
    } = props
    const { billToLocation } = formValues
    const dispatch = useDispatch()
    const { openSnackbar } = useSnackbarContext()
    const { uploadFile, setOrderMappingData, errors } = useOrdersContext()
    const [isLoading, setIsLoading] = useState(false)

    const selectedLocation =
        locations.find(
            location => location.cpgCode === formValues?.billToLocation?.value
        ) ?? {}

    const isShipperOnlyFreightDirect =
        selectedLocation?.isShipperEnabled &&
        !selectedLocation?.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isThirdPartyOnlyFreightDirect =
        !selectedLocation?.isShipperEnabled &&
        selectedLocation?.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isDoubleAccountFreightDirect =
        selectedLocation?.isShipperEnabled &&
        selectedLocation?.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isThirdPartyOnly =
        billToLocation?.fedexBillToAccount &&
        !billToLocation?.fedexFreightAccount

    const correctMode = isThirdPartyOnly
        ? "thirdParty"
        : isShipperOnlyFreightDirect
        ? "outbound"
        : formValues?.mode

    const correctSampleFile =
        isThirdPartyOnlyFreightDirect || isDoubleAccountFreightDirect
            ? "freightDirect_thirdParty"
            : isShipperOnlyFreightDirect
            ? "freightDirect"
            : isThirdPartyOnly
            ? "thirdParty"
            : formValues?.mode

    const relevantSampleFile = `/static/orders_${language}_${correctSampleFile}_sample.csv`
    const relevantUploadGuide = `/static/orders_${language}_upload_guide.pdf`

    const onBillToLocationChange = option => {
        const isThirdPartyOnly =
            !option.fedexFreightAccount && option.fedexBillToAccount

        const newMode = isThirdPartyOnly ? "thirdParty" : "outbound"
        dispatch(change("importOrders", "mode", newMode))
    }

    const onUploadFile = async e => {
        logGAEvent(
            "Orders",
            "Import Modal - Upload File Chosen",
            selectedLocation?.locationType
        )

        const file = e.target.files[0]
        setIsLoading(true)

        try {
            const newOrderMappingData = await uploadFile(
                selectedLocation?.cpgCode,
                correctMode,
                file
            )
            setOrderMappingData(newOrderMappingData?.data)
            setModalOpen(false)
            setImportMappingModal(true)
            logGAEvent(
                "Orders",
                "Import Modal - Upload File Success",
                selectedLocation?.locationType
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="orders__uploadError"
                    defaultMessage="Error Uploading Orders"
                />,
                3000
            )

            logGAEvent(
                "Orders",
                "Import Modal - Upload File Error",
                selectedLocation?.locationType
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Grid
            item
            container
            className={classes.paper}
            alignContent="flex-start"
        >
            {isLoading ? <GlobalSpinner /> : null}
            <Grid
                item
                container
                direction="column"
                alignContent="flex-start"
                className={classes.mainContainer}
            >
                <Grid item container>
                    <Typography variant="h4">
                        <FormattedMessage
                            id="orders.import__title"
                            defaultMessage="Import Orders by Location"
                        />
                    </Typography>
                </Grid>
                <Grid item container className={classes.section}>
                    <Field
                        component={FormSelectBillTo}
                        name="billToLocation"
                        label={
                            <FormattedMessage
                                id="getRates.form__selectLocation"
                                defaultMessage="Select Location"
                            />
                        }
                        options={formattedLocations}
                        onChange={option => {
                            onBillToLocationChange(option)
                        }}
                        category={gaCategory}
                    ></Field>
                </Grid>
                {!isShipperOnlyFreightDirect &&
                    selectedLocation?.isShipperEnabled && (
                        <Grid item container className={classes.section}>
                            <Field
                                name="mode"
                                component={FormRadio}
                                options={
                                    isDoubleAccountFreightDirect
                                        ? modeOptions.doubleAccountFreightDirect
                                        : selectedLocation?.is3rdPartyEnabled
                                        ? modeOptions.shipperAndThirdParty
                                        : modeOptions.shipperOnly
                                }
                                required
                                category={gaCategory}
                            />
                        </Grid>
                    )}
                {errors.length ? (
                    <Grid item container className={classes.errorsContainer}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="orders.import__errors"
                                defaultMessage="Import errors on {count} lines.  Please fix and re-upload."
                                values={{ count: errors.length }}
                            />
                        </Typography>
                        <Grid item container>
                            {errors.map(error => {
                                return error?.cause.map(causeMessage => {
                                    return (
                                        <Grid
                                            item
                                            container
                                            className={classes.errorEntry}
                                            alignItems="center"
                                        >
                                            <Typography
                                                variant="body2"
                                                color="inherit"
                                            >
                                                <FormattedMessage
                                                    id="orders.import__line"
                                                    defaultMessage="Line {number} - {causeMessage} "
                                                    values={{
                                                        number: error.line - 1,
                                                        causeMessage,
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    )
                                })
                            })}
                        </Grid>
                    </Grid>
                ) : null}
                <Grid
                    item
                    container
                    alignItems="flex-end"
                    className={classes.buttonContainer}
                    justifyContent="space-between"
                >
                    <Grid item className={classes.buttonGroup}>
                        <Button
                            className={classes.documentButton}
                            variant="contained"
                            color="primary"
                            disabled={!formValues.billToLocation}
                            onClick={() => {
                                logGAEvent(
                                    gaCategory,
                                    "Import Modal - Download Sample Click",
                                    selectedLocation?.locationType
                                )
                                window.open(relevantSampleFile, "_blank")
                            }}
                        >
                            <FormattedMessage
                                id="orders.import__downloadSample"
                                defaultMessage="Download Sample CSV"
                            />
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={!formValues.billToLocation}
                            className={classes.documentButton}
                            onClick={() => {
                                logGAEvent(
                                    gaCategory,
                                    "Import Modal - Download Upload Guide Click",
                                    selectedLocation?.locationType
                                )
                                window.open(relevantUploadGuide, "_blank")
                            }}
                        >
                            <FormattedMessage
                                id="orders.import__uploadGuide"
                                defaultMessage="Upload Guide"
                            />
                        </Button>
                    </Grid>
                    <Grid item className={classes.uploadButton}>
                        <input
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            accept=".csv"
                            onChange={onUploadFile}
                            onClick={event => {
                                event.target.value = null
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!formValues.billToLocation}
                            htmlFor="contained-button-file"
                            component="label"
                            onClick={() => {
                                logGAEvent(
                                    gaCategory,
                                    "Import Modal - Import Button Click",
                                    selectedLocation?.locationType
                                )
                            }}
                        >
                            <FormattedMessage
                                id="orders.import__upload"
                                defaultMessage="Upload"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

export default withStyles(styles)(ImportModal)
