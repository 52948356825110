import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import { CircularProgress, Typography } from "@material-ui/core"
import { get } from "lodash"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const styles = theme => ({
    alert__box__container: {
        backgroundColor: "#D4D4D4",
        padding: "5px",
        borderBottom: "1px solid #A9A9A9",
    },
    dismiss__button: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    whiteSpace: {
        whiteSpace: "pre-wrap",
    },
})

const AlertBox = ({
    classes,
    dismissAlert,
    event,
    shipmentId,
    proNumber,
    gaCategory,
}) => {
    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent } = useGAContext()
    const [isLoading, setIsLoading] = useState(false)

    return (
        <Grid
            container
            className={classes.alert__box__container}
            alignItems="center"
        >
            <Grid item container xs={1} justify="flex-start">
                <WarningIcon />
            </Grid>
            <Grid item container xs={2} justify="flex-start">
                {proNumber && (
                    <Typography variant="body2">{`#${proNumber}`}</Typography>
                )}
            </Grid>
            <Grid
                item
                container
                xs={8}
                alignItems="center"
                className={classes.whiteSpace}
            >
                {get(event, "created_at") && (
                    <Typography variant="caption">{`${moment(
                        get(event, "created_at")
                    ).format("dddd MMM-Do h:mm A")}: `}</Typography>
                )}
                <Typography variant="body2">
                    {get(event, "description")}
                </Typography>
            </Grid>
            {!get(event, "dismissed") && (
                <Grid
                    item
                    container
                    xs={1}
                    alignItems="center"
                    justify="flex-end"
                >
                    {isLoading ? (
                        <CircularProgress size={20} color="secondary" />
                    ) : (
                        <Typography
                            variant="body2"
                            color="primary"
                            className={classes.dismiss__button}
                            onClick={async () => {
                                setIsLoading(true)
                                try {
                                    logGAEvent(
                                        gaCategory,
                                        "Alert Dismiss Click"
                                    )
                                    await dismissAlert(shipmentId, event._id)
                                } catch (error) {
                                    openSnackbar(
                                        "error",
                                        <FormattedMessage
                                            id="orderDetails__Alertbox__dismissError"
                                            defaultMessage="Error dismissing warning"
                                        />,
                                        4000
                                    )
                                } finally {
                                    setIsLoading(false)
                                }
                            }}
                        >
                            <FormattedMessage
                                id="alertBox__dismiss"
                                defaultMessage="Dismiss"
                            />
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    )
}

export default withStyles(styles)(AlertBox)
