import React from "react"
import { FormattedMessage } from "react-intl"

export const trackingIdBeingChecked = (
    <FormattedMessage
        id="error__trackingIdBeingChecked"
        defaultMessage="Tracking ID is being checked. Please wait for it to finish."
    />
)
export const noRateResults = (
    <FormattedMessage
        id="error__noRateResults"
        defaultMessage="No rate quote item"
    />
)
export const rateRequestError = (
    <FormattedMessage
        id="error__rateRequestError"
        defaultMessage="Could not complete request. Please click the 'Get Rates' button again."
    />
)
export const bolPrintError = (
    <FormattedMessage
        id="error__bolPrintError"
        defaultMessage="Could not print BOL document. Please try again later."
    />
)
export const chatForbidden = (
    <FormattedMessage
        id="error__chatForbidden"
        defaultMessage="Not authorized to view this channel"
    />
)
export const chatSubscriptionError = (
    <FormattedMessage
        id="error__chatSubscriptionError"
        defaultMessage="Channel subscription error"
    />
)
export const emailSendError = (
    <FormattedMessage
        id="error__emailSendError"
        defaultMessage="Could not send email."
    />
)
export const labelPrintError = (
    <FormattedMessage
        id="error__labelPrintError"
        defaultMessage="Could not print labels. Please try again later."
    />
)
export const contactSaveError = (
    <FormattedMessage
        id="error__contactSaveError"
        defaultMessage="Could not save contact."
    />
)
export const contactDeleteError = (
    <FormattedMessage
        id="error__contactDeleteError"
        defaultMessage="Could not delete contact."
    />
)
export const locationAddError = (
    <FormattedMessage
        id="error__locationAddError"
        defaultMessage="Could not add location."
    />
)
export const requestAccessError = (
    <FormattedMessage
        id="error__requestAccessError"
        defaultMessage="You have already requested access to this location.  An existing owner will need to grant you access."
    />
)
export const userUpdateError = (
    <FormattedMessage
        id="error__userUpdateError"
        defaultMessage="Could not update user."
    />
)
export const contactsImportError = (
    <FormattedMessage
        id="error__contactsImportError"
        defaultMessage="Could not import contacts."
    />
)

export const discountsImportError = err => (
    <FormattedMessage
        id="error__discountsImportError"
        defaultMessage="Uploading freight direct discounts failed."
        values={{ err: err ? err : "" }}
    />
)
export const contactsImportCSVLoadError = err => (
    <FormattedMessage
        id="error__contactsImportCSVLoadError"
        defaultMessage={`CSV parsing error. {err}`}
        values={{ err: err ? err : "" }}
    />
)
export const contactsImportCSVTooLargeError = (
    <FormattedMessage
        id="error__contactsImportCSVTooLargeError"
        defaultMessage="CSV file too large, please split the file to 1000 row chunks"
    />
)
export const shareQuoteError = (
    <FormattedMessage
        id="error__shareQuoteError"
        defaultMessage="Could not share quotes."
    />
)
export const itemSaveError = (
    <FormattedMessage
        id="error__itemSaveError"
        defaultMessage="Could not save item."
    />
)
export const itemDeleteError = (
    <FormattedMessage
        id="error__itemDeleteError"
        defaultMessage="Could not delete item."
    />
)
export const addLocationError = message => (
    <FormattedMessage
        id="error__addLocationError"
        defaultMessage={
            "{message} Please verify that your address and/or FedEx account number(s) match exactly what FedEx has on file. If you need further assistance please contact FedEx at (1.800.463.3339)."
        }
        values={{ message }}
    />
)

export const documentSaveError = documentType => (
    <FormattedMessage
        id="error__documentSaveError"
        defaultMessage="Could not save {documentType}."
        values={{ documentType }}
    />
)

export const documentAddError = documentType => (
    <FormattedMessage
        id="error__documentAddError"
        defaultMessage="Could not add {documentType}."
        values={{ documentType }}
    />
)

export const documentIncompatibleError = (
    <FormattedMessage
        id="error__documentIncompatibleError"
        defaultMessage=" Please retry with a .pdf, .png, or .jpeg file that is 5MB or less"
    />
)

export const userSignUpError = userFirstName => (
    <FormattedMessage
        id="error__userSignUpError"
        defaultMessage="Dear {userFirstName}, {br}
    <b>We have been unable to validate your account. Please verify that the account number entered:</b> 
    <ul>
 <li>	has either 8 or 9 digits </li>
 <li>	is your FedEx Freight account (as opposed to parcel) </li>
 <li>	and that you selected the correct type (shipper account or third-party account). </li>
    </ul>
If you have verified the above information, please contact your FedEx sales representative. They need to validate with you that: 
    <ul>
 <li>	your addresses on file are accurate </li>
 <li>	your account is not on hold, set to cash payments, or has been archived. </li>
    </ul>
If you have any additional questions, email us at  <a>ltlselect@fedex.com</a>.
Thank you for choosing LTL Select."
        values={{
            userFirstName,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            a: (...chunks) => (
                <a href="mailto: ltlselect@fedex.com">{chunks}</a>
            ),
        }}
    />
)

export const accountTypeError = (origin, destination) => (
    <FormattedMessage
        id="error__accountTypeError"
        defaultMessage="Incorrect account type for shipment from {origin} to {destination}. Please select new origin or destination country"
        values={{ origin, destination }}
    />
)
