import React, { useEffect, useState } from "react"
import { Element, scroller } from "react-scroll"
import { uniqWith, isEqual } from "lodash"
import Grid from "@material-ui/core/Grid"
import { FormattedMessage, useIntl } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import RatesError from "../atoms/RatesError"
import moment from "moment"
import ShareIcon from "@material-ui/icons/Share"
import { Button } from "@material-ui/core"
import { CarrierLogo } from "../../../util"
import RateRow from "../atoms/RateRow"
import { isGuaranteedQuote } from "../../../quote/card"
import RatesSummary from "../summaryCards/RatesSummary"
import { RateShareModal } from "../atoms/share/RateShareModal"
import FXFDRates from "../atoms/freightDirect/FXFDRates"
import FXFDReturnsRates from "../atoms/freightDirectReturns/FXFDReturnsRates"
import FormSwitch from "../../../form/form-switch"
import { Field } from "redux-form"
import { useFlags } from "launchdarkly-react-client-sdk"
import InfoToolTip from "../../../common/InfoToolTip"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../../util/carrierLogoUtils"
import { isOffshoreCalculatedFedexRate } from "../../../../actions/validation"
import { isPresentMatchingErrors } from "../../../util/ordersUtils"
import FastestPriorityChip from "../atoms/FastestPriorityChip"

const extractTerminalAddress = terminal => {
    return {
        address: { ...terminal.address },
        name: terminal?.location,
    }
}

const extractTerminalContact = terminal => {
    const { contact = {} } = terminal

    return {
        name: contact.name,
        phone: {
            phone_number: contact.phone,
            extension: contact.extension,
        },
        email: {
            email_address: contact.email,
        },
    }
}

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    block: {
        padding: "5px",
    },
    dateGroup: {
        padding: "10px 0",
        borderTop: "solid 2px #969696",
    },
    carrierGroup: {
        padding: "10px 0",
        borderTop: "dotted 1px #969696",
    },
    carrierGroup__first: {
        padding: "10px 0",
    },
    rateRow: {
        padding: "10px 0",
    },
    expansionPanel: {
        width: "100%",
        boxShadow: "none",
        backgroundColor: "rgb(242, 242, 242)",
    },
    expansionPanelSummary: {
        cursor: "default",
    },
    shareButton: {
        marginLeft: "8px",
    },
    rerun: {
        paddingBottom: "16px",
    },
    ratesError: {
        paddingBottom: "16px",
    },
    shareContainer: {
        paddingBottom: "16px",
    },
    toolTip: {
        padding: "16px 0px",
    },
    chipRoot: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    chipLabel: {
        overflowWrap: "break-word",
        whiteSpace: "normal",
        textOverflow: "clip",
    },
})

const ltlFilter = rate => rate.mode !== "LTL"
const thirdPartyFilter = rate => rate.direction !== "THIRD_PARTY"
const volumeFilter = rate => rate.mode !== "VOLUME_LTL"
const ltlDirectFilter = rate => rate.mode !== "LTL_DIRECT"

export default function Rates({
    editMode,
    currentStep,
    guaranteedList,
    permissions = {},
    formValues = {},
    shipmentId,
    activeQuote = {
        result: { rateQuotes: [], ratesError: [] },
        shippedRateQuote: {},
    },
    handleComplete,
    handleEdit,
    changeField,
    isQuickRate,
    trackGA,
    onClickReRunRates,
    isLoading,
    setEditMode,
    IntercomAPI,
    setIsPieceWeightDialogOpen,
}) {
    useEffect(() => {
        if (!isQuickRate) {
            if (currentStep === 4) {
                IntercomAPI("trackEvent", "book-shipment-rates")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const GA_CATEGORY = isQuickRate ? "Quick Rate" : "Book Shipment - Rates"
    const classes = useStyles()
    const intl = useIntl()
    const {
        domesticOffshoreRating,
        domesticOffshoreAkRating,
        domesticOffshoreHiRating,
        priorityPlusChip,
    } = useFlags()

    const [rateBuckets, setRateBuckets] = useState({})
    const [hasLTLRate, setHasLTLRate] = useState(false)
    const [hasThirdPartyRate, setHasThirdPartyRate] = useState(false)
    const [hasVolumeRate, setHasVolumeRate] = useState(false)
    const [hasLTLDirectRate, setHasLTLDirectRate] = useState(false)
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [isSharing, setIsSharing] = useState(false)
    const [selections, setSelections] = useState(new Set())

    const { viewRateAmounts, createRates } = permissions

    const canViewRateAmounts = viewRateAmounts?.value

    const { singleWorkflowLtlAndLtlDirect } = useFlags()

    const {
        isFreightDirect,
        isFreightDirectReturns,
        selectedLocation = {},
        destination = {},
        hideLTLRates,
        hideThirdPartyRates,
        hideVolumeRates,
        hideFXFDRates,
        origin = {},
        paymentType,
        handlingUnits = [],
        cpg,
    } = formValues

    const { shipped, expired, shippedRateQuote } = activeQuote
    const disabledVolumenRatesCountries = ["MX"]
    const destinationCountry =
        activeQuote?.search?.destination?.shippingAddress?.address?.country ??
        "US"
    useEffect(() => {
        if (currentStep === 4 || isQuickRate) {
            scroller.scrollTo("ratesTitle", {
                smooth: true,
                duration: 500,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, isQuickRate])

    useEffect(() => {
        if (isFreightDirect && !isFreightDirectReturns) {
            setRateBuckets(
                processFXFDRates(
                    activeQuote?.result?.rateQuotes,
                    destinationCountry
                )
            )
        } else if (isFreightDirectReturns) {
            processFXFDReturnsRates(activeQuote)
        } else {
            processRates(activeQuote?.result?.rateQuotes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        activeQuote,
        hideLTLRates,
        hideThirdPartyRates,
        hideVolumeRates,
        hideFXFDRates,
        isFreightDirect,
        isFreightDirectReturns,
    ])

    useEffect(() => {
        const ratesAndErrors = [
            ...activeQuote?.result?.rateQuotes,
            ...activeQuote?.result?.ratesError,
        ]

        setHasLTLRate(ratesAndErrors?.some(x => !ltlFilter(x)))
        setHasThirdPartyRate(ratesAndErrors?.some(x => !thirdPartyFilter(x)))
        setHasLTLDirectRate(ratesAndErrors?.some(x => !ltlDirectFilter(x)))

        if (
            disabledVolumenRatesCountries.includes(
                origin?.shippingAddress?.address?.country
            ) ||
            disabledVolumenRatesCountries.includes(
                destination?.shippingAddress?.address?.country
            )
        ) {
            setHasVolumeRate(false)
        } else {
            setHasVolumeRate(ratesAndErrors?.some(x => !volumeFilter(x)))
        }
    }, [activeQuote, origin, destination, disabledVolumenRatesCountries])

    const isFXFDRateWithError = rate =>
        singleWorkflowLtlAndLtlDirect &&
        !isFreightDirect &&
        !ltlDirectFilter(rate) &&
        rate?.errorMessages?.length &&
        !rate?.rateQuoteDetail?.totalInPreferredCurrency &&
        !rate?.rateQuoteDetail?.total

    const processRates = rateQuotes => {
        const tempRateBuckets = rateQuotes
            .filter(
                x =>
                    (!hideLTLRates || ltlFilter(x)) &&
                    (!hideThirdPartyRates || thirdPartyFilter(x)) &&
                    (!hideVolumeRates || volumeFilter(x)) &&
                    (!hideFXFDRates || ltlDirectFilter(x)) &&
                    !isFXFDRateWithError(x)
            )
            .map(rate => ({
                ...rate,
                deliveryDateTime: moment(rate.deliveryDateTime),
                deliveryDateRange: rate.deliveryDateRange
                    ? {
                          earliestEstimatedDate: moment(
                              rate.deliveryDateRange?.earliestEstimatedDate
                          ),
                          latestEstimatedDate: moment(
                              rate.deliveryDateRange?.latestEstimatedDate
                          ),
                      }
                    : null,
                guaranteed: isGuaranteedQuote(
                    rate.serviceLevel && rate.serviceLevel.code,
                    guaranteedList
                ),
            }))
            .sort(defaultSortMethod)
            .reduce((prev, rate) => {
                const date = moment(rate.deliveryDateTime).format("YYYYMMDD")
                const bucketCategory = `${rate.carrierCode}.${rate.mode}`

                const adjustedRate = {
                    ...rate,
                    guaranteed: isGuaranteedQuote(
                        rate.serviceLevel && rate.serviceLevel.code,
                        guaranteedList
                    ),
                }

                prev[date] = prev[date] ?? {}
                prev[date][bucketCategory] = [
                    ...(prev[date][bucketCategory] ?? []),
                    adjustedRate,
                ]
                return prev
            }, {})

        setRateBuckets(tempRateBuckets)
    }

    const processFXFDRates = (rateQuotes, destination_country = "US") => {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic__title"
                        defaultMessage="Basic"
                    />
                ),
                label: (
                    <FormattedMessage
                        id={
                            destination_country === "CA"
                                ? "rateResults.freightDirect__basic_des_canadian"
                                : "rateResults.freightDirect__basic_des_us"
                        }
                        defaultMessage="Front door, driveway, dock, curbside or garage threshold with no signature"
                    />
                ),
                info: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic_info"
                        defaultMessage="FedEx Freight Direct Basic — LTL service which includes bundled accessorials, commonly used for residential or limited access recipient. Delivery to ground level (front or back door) or garage without an appointment (no signature required) 2,000 lb. max/pallet"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus__title"
                        defaultMessage="Basic by Appointment"
                    />
                ),
                label: (
                    <FormattedMessage
                        id={
                            destination_country === "CA"
                                ? "rateResults.freightDirect__basicPlus_des_canadian"
                                : "rateResults.freightDirect__basicPlus_des_us"
                        }
                        defaultMessage="Front door, driveway, dock, curbside or garage threshold with signature"
                    />
                ),
                info: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus_info"
                        defaultMessage="FedEx Freight Direct Basic by Appointment — LTL service with bundled accessorials, commonly used for residential or limited access recipient. Delivery to ground level (front or back door) or garage with an appointment, 2,000 lb. max/pallet"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard__title"
                        defaultMessage="Standard"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard"
                        defaultMessage="To the first ground-level room"
                    />
                ),
                info: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard_info"
                        defaultMessage="FedEx Freight Direct Standard — LTL service with bundled accessorials, commonly used for residential or limited access recipient. Inside delivery to ground-level room in the home or business with an appointment, 2,000 lb. max/pallet"
                    />
                ),
                rates: [],
            },
            premium: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium__title"
                        defaultMessage="Premium"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium"
                        defaultMessage="Room of choice and packaging removal by request"
                    />
                ),
                info: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium_info"
                        defaultMessage="FedEx Freight Direct Premium — LTL service with bundled accessorials, commonly used for residential or limited access recipient. Used for shipments requiring a two-person delivery, and/or placement in room of choice with an appointment, with the options of packaging removal"
                    />
                ),
                rates: [],
            },
            assembly: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__assembly__title"
                        defaultMessage="Assembly"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__assembly"
                        defaultMessage="Room of choice, assembly and packaging removal by request"
                    />
                ),
                rates: [],
            },
        }

        rateQuotes.forEach(individualRate => {
            switch (individualRate.serviceLevel?.code) {
                case "BASIC":
                    formattedRates.basic.rates.push(individualRate)
                    break
                case "BASIC_APPT":
                    formattedRates.basicPlus.rates.push(individualRate)
                    break
                case "STANDARD":
                    formattedRates.standard.rates.push(individualRate)
                    break
                case "PREMIUM":
                    formattedRates.premium.rates.push(individualRate)
                    break
                case "ASSEMBLY":
                    formattedRates.assembly.rates.push(individualRate)
                    break
                default:
                    break
            }
        })
        return formattedRates
    }

    const processFXFDReturnsRates = rateResult => {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPickup__title"
                        defaultMessage="Basic Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basic"
                        defaultMessage="Front door, back door, or from the garage with no signature"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup__title"
                        defaultMessage="Basic by Appointment Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup"
                        defaultMessage="Front door, back door, or from the garage with signature"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup__title"
                        defaultMessage="Standard Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup"
                        defaultMessage="From the first ground-level room"
                    />
                ),
                rates: [],
            },
            // premium: {
            //     title: (
            //         <FormattedMessage
            //             id="rateResults.freightDirectReturns__premiumPickup__title"
            //             defaultMessage="Premium"
            //         />
            //     ),
            //     label: (
            //         <FormattedMessage
            //             id="rateResults.freightDirectReturns__premiumPickup"
            //             defaultMessage="Room of choice and packaging by request"
            //         />
            //     ),
            //     rates: [],
            // },
        }

        rateResult.result.rateQuotes.forEach(individualRate => {
            switch (individualRate.serviceLevel?.code) {
                case "BASIC_PICKUP":
                    formattedRates.basic.rates.push(individualRate)
                    break
                case "BASIC_APPT_PICKUP":
                    formattedRates.basicPlus.rates.push(individualRate)
                    break
                case "STANDARD_PICKUP":
                    formattedRates.standard.rates.push(individualRate)
                    break
                // case "PREMIUM_PICKUP":
                //     formattedRates.premium.rates.push(individualRate)
                //     break
                default:
                    break
            }
        })

        setRateBuckets(formattedRates)
    }

    const setIsSelected = (id, value) => {
        const newSet = new Set(selections)
        if (value) {
            newSet.add(id)
            setSelections(newSet)
        } else {
            if (newSet.has(id)) {
                newSet.delete(id)
            }
            setSelections(newSet)
        }
    }

    const defaultSortMethod = (a, b) => {
        if (
            (b.guaranteed && a.guaranteed) ||
            (!b.guaranteed && !a.guaranteed)
        ) {
            return moment(a.deliveryDateTime).isAfter(
                moment(b.deliveryDateTime)
            )
                ? 1
                : -1
        }
        return b.guaranteed ? 1 : -1
    }

    const freightDirectPieceWeightCheck = rate => {
        if (hasLTLRate && hasLTLDirectRate) {
            if (
                rate?.mode === "LTL_DIRECT" &&
                (rate?.serviceLevel?.code === "STANDARD" ||
                    rate?.serviceLevel?.code === "PREMIUM")
            ) {
                for (let hu of handlingUnits) {
                    for (let item of hu.items) {
                        if (!item.freightDirectPiecesToggle) {
                            setIsPieceWeightDialogOpen(true)
                            return true
                        }
                    }
                }
            }
        }
    }

    const selectRate = rate => {
        if (singleWorkflowLtlAndLtlDirect) {
            if (freightDirectPieceWeightCheck(rate)) return
        }

        const canDoThirdParty =
            selectedLocation?.fedexBillToAccount ||
            selectedLocation?.pickupAndDestroyLocation?.fedexBillToAccount

        const isPickAndDestroyRate =
            rate?.serviceLevel?.code === "BASIC_PICKUP_AND_DESTROY"

        const changeToTerminal = isPickAndDestroyRate && canDoThirdParty

        if (changeToTerminal) {
            changeField("destination", {
                ...destination,
                shippingAddress: extractTerminalAddress(rate?.terminal),
                contact: extractTerminalContact(rate?.terminal),
            })
        }

        changeField("selectedRate", rate)
        changeField("identifiers", activeQuote?.identifiers ?? {})
        setEditMode(false)
        handleComplete()
    }

    const FXFDRateErrorsAndRateErrors = [
        ...activeQuote?.result?.rateQuotes.filter(rate =>
            isFXFDRateWithError(rate)
        ),
        ...activeQuote?.result?.ratesError,
    ]

    const ratesError = uniqWith(
        FXFDRateErrorsAndRateErrors?.filter(
            x =>
                (!hideLTLRates || ltlFilter(x)) &&
                (!hideThirdPartyRates || thirdPartyFilter(x)) &&
                (!hideVolumeRates || volumeFilter(x)) &&
                (!hideFXFDRates || ltlDirectFilter(x))
        ),
        (a, b) =>
            a.carrierCode === b.carrierCode &&
            isEqual(a.errorMessages, b.errorMessages)
    )
    const errorsAndWarningsToMatch = [
        `${intl.formatMessage({
            id: "getRates.capacityError",
            defaultMessage: "shipment exceeds",
        })}`,
        `${intl.formatMessage({
            id: "book.capLoad__dialogContent.first",
            defaultMessage:
                "This shipment could incur additional charges based on the size and your account-specific rates. Please contact Volume Services at 1.888.465.5646 to understand the precise cost of your shipment. For more information: ",
        })}`,
        `${intl.formatMessage({
            id: "rateResults__extremeLengthWarning",
            defaultMessage:
                "This shipment could incur additional charges based on the handling unit length and your account-specific rates.",
        })}`,
    ]

    const isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError = isPresentMatchingErrors(
        ratesError,
        errorsAndWarningsToMatch
    )

    const hasMultipleRateCategories =
        [hasLTLRate, hasThirdPartyRate, hasVolumeRate, hasLTLDirectRate].filter(
            x => x
        ).length > 1

    const hasMultipleAvailableRateFilters =
        [
            hasLTLRate && !hideLTLRates,
            hasThirdPartyRate && !hideThirdPartyRates,
            hasVolumeRate && !hideVolumeRates,
            hasLTLDirectRate && !hideFXFDRates,
        ].filter(x => x).length > 1

    if (currentStep === 4 || isQuickRate) {
        return (
            <Grid item container>
                <RateShareModal
                    open={isShareModalOpen}
                    handleClose={() => {
                        setIsShareModalOpen(false)
                    }}
                    selectedRates={selections}
                    activeQuote={activeQuote}
                    shipmentId={shipmentId}
                    gaCategory={GA_CATEGORY}
                />
                <Grid item container justify="center">
                    <Typography variant="h6">
                        <FormattedMessage
                            id="bookShipment.rates__title"
                            defaultMessage="Rates"
                        />
                    </Typography>
                    <Element name="ratesTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    {!shipped && expired && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="center"
                            direction="column"
                            className={classes.rerun}
                        >
                            <Grid item container justify="center">
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="bookShipment__outdated"
                                        defaultMessage="This rate is outdated."
                                    />
                                </Typography>
                            </Grid>
                            {createRates?.value && (
                                <Grid item container justify="center">
                                    <Button
                                        size="small"
                                        color="primary"
                                        disabled={isLoading}
                                        variant="outlined"
                                        onClick={() => {
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate"
                                                    : "Book Shipment",
                                                "Re-Run Expired Rate Click"
                                            )
                                            onClickReRunRates()
                                        }}
                                    >
                                        <FormattedMessage
                                            id="bookShipment__rerunRate"
                                            defaultMessage="Click here to re-run the rate"
                                        />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <Grid item container alignContent="flex-start">
                        <Grid
                            item
                            container
                            xs={9}
                            className={classes.ratesError}
                        >
                            {ratesError.length > 0 && (
                                <RatesError
                                    ratesError={ratesError}
                                    isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError={
                                        isPresentAnyCapacityOrCapLoadWarningOrExtremeLengthError
                                    }
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={3}
                            justify="flex-end"
                            className={classes.shareContainer}
                        >
                            {isSharing && (
                                <Grid item>
                                    <Button
                                        id="cancelShareRates"
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setIsSharing(false)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate"
                                                    : "Book Shipment",
                                                "Share",
                                                "Share mode cancel"
                                            )
                                        }}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                </Grid>
                            )}
                            <Grid item className={classes.shareButton}>
                                <Button
                                    id="shareRates"
                                    variant={
                                        !isSharing ? "outlined" : "contained"
                                    }
                                    color="secondary"
                                    disabled={
                                        (isSharing && selections.size === 0) ||
                                        isLoading
                                    }
                                    onClick={() => {
                                        if (isSharing) {
                                            setIsShareModalOpen(true)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate"
                                                    : "Book Shipment",
                                                "Share",
                                                "Modal Open"
                                            )
                                        } else {
                                            setIsSharing(true)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate"
                                                    : "Book Shipment",
                                                "Share",
                                                "Share mode enabled"
                                            )
                                        }
                                    }}
                                >
                                    <ShareIcon className={classes.leftIcon} />
                                    <FormattedMessage
                                        id="rateResults.share__share"
                                        defaultMessage="Share"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        {hasMultipleRateCategories && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                            >
                                {hasThirdPartyRate && (
                                    <Grid xs={2} item>
                                        <Field
                                            category={GA_CATEGORY}
                                            component={FormSwitch}
                                            name="hideThirdPartyRates"
                                            disabled={
                                                !hideThirdPartyRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideThirdPartyRates"
                                                    defaultMessage="Hide Third Party Rates"
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                {hasVolumeRate && (
                                    <Grid xs={2} item>
                                        <Field
                                            category={GA_CATEGORY}
                                            component={FormSwitch}
                                            name="hideVolumeRates"
                                            disabled={
                                                !hideVolumeRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideVolumeRates"
                                                    defaultMessage="Hide Volume LTL Rates"
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                {hasLTLRate && (
                                    <Grid xs={2} item>
                                        <Field
                                            category={GA_CATEGORY}
                                            component={FormSwitch}
                                            name="hideLTLRates"
                                            disabled={
                                                !hideLTLRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideLTLRates"
                                                    defaultMessage="Hide LTL Rates"
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                {hasLTLRate &&
                                    hasLTLDirectRate &&
                                    singleWorkflowLtlAndLtlDirect && (
                                        <Grid xs={2} item>
                                            <Field
                                                category={GA_CATEGORY}
                                                component={FormSwitch}
                                                name="hideFXFDRates"
                                                label={
                                                    <FormattedMessage
                                                        id="bookShipment.hideFXFDRates"
                                                        defaultMessage="Hide FXFD Rates"
                                                    />
                                                }
                                            ></Field>
                                        </Grid>
                                    )}
                                {hasLTLRate ||
                                hasLTLDirectRate ||
                                hasVolumeRate ||
                                hasMultipleRateCategories ? (
                                    <Grid xs={1} justifyContent="flex-end" item>
                                        <InfoToolTip
                                            item
                                            className={classes.toolTip}
                                            text={
                                                <FormattedMessage
                                                    id="bookShipment.rateTogglesToolTip"
                                                    defaultMessage="Use these toggles to customize which shipping rates you want to see."
                                                />
                                            }
                                        ></InfoToolTip>
                                    </Grid>
                                ) : null}
                            </Grid>
                        )}
                        <Grid item container>
                            {isFreightDirect &&
                            !isFreightDirectReturns &&
                            Object.keys(rateBuckets).length > 0 &&
                            activeQuote?.result?.rateQuotes?.length > 0 ? (
                                <Grid item container>
                                    <FXFDRates
                                        category={GA_CATEGORY}
                                        rateBuckets={rateBuckets}
                                        classes={classes}
                                        isSharing={isSharing}
                                        selections={selections}
                                        setIsSelected={setIsSelected}
                                        selectRate={selectRate}
                                        canViewRateAmounts={true}
                                        expired={expired}
                                        shippedRateQuote={shippedRateQuote}
                                        shipped={shipped}
                                        paymentType={paymentType}
                                        isSingleWorkflow={false}
                                    />
                                </Grid>
                            ) : null}
                            {isFreightDirectReturns &&
                                Object.keys(rateBuckets).length > 0 &&
                                activeQuote?.result?.rateQuotes?.length > 0 && (
                                    <Grid item container>
                                        <FXFDReturnsRates
                                            category={GA_CATEGORY}
                                            trackGA={trackGA}
                                            rateBuckets={rateBuckets}
                                            classes={classes}
                                            isSharing={isSharing}
                                            selections={selections}
                                            setIsSelected={setIsSelected}
                                            selectRate={selectRate}
                                            canViewRateAmounts={
                                                canViewRateAmounts
                                            }
                                            expired={expired}
                                            shippedRateQuote={shippedRateQuote}
                                            shipped={shipped}
                                            paymentType={paymentType}
                                        />
                                    </Grid>
                                )}
                            {!isFreightDirect &&
                                !isFreightDirectReturns &&
                                activeQuote?.result?.rateQuotes?.length > 0 &&
                                Object.keys(rateBuckets)
                                    .sort((a, b) => {
                                        return moment(a).isAfter(moment(b))
                                            ? 1
                                            : -1
                                    })
                                    .map((dateGroup, dateIndex) => {
                                        const firstBucketCategoryKey = Object.keys(
                                            rateBuckets[dateGroup]
                                        )[0]

                                        const firstRateEntryAtFirstCategory =
                                            rateBuckets[dateGroup][
                                                firstBucketCategoryKey
                                            ][0]

                                        const deliveryDate =
                                            firstRateEntryAtFirstCategory.transitDays &&
                                            moment(
                                                firstRateEntryAtFirstCategory.deliveryDateTime
                                            )

                                        const firstPriorityRate = Object.keys(
                                            rateBuckets[dateGroup]
                                        )
                                            .filter(
                                                key =>
                                                    !key.includes("LTL_DIRECT")
                                            )
                                            .map(categoryKey =>
                                                rateBuckets[dateGroup][
                                                    categoryKey
                                                ].find(
                                                    rate =>
                                                        rate?.carrierCode ==
                                                        "FXFE"
                                                )
                                            )
                                            .find(
                                                rate =>
                                                    rate?._id !== null &&
                                                    rate?._id !== undefined
                                            )
                                        return (
                                            <Grid
                                                key={dateGroup}
                                                item
                                                container
                                                alignContent="flex-end"
                                                className={classes.dateGroup}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    xs={3}
                                                    direction="column"
                                                >
                                                    {firstRateEntryAtFirstCategory.deliveryDateRange ? (
                                                        <Grid>
                                                            <Typography className="left-padding call-carrier">
                                                                <FormattedMessage
                                                                    id="rateResults__dateRange"
                                                                    defaultMessage="Expected Delivery Timeframe:"
                                                                ></FormattedMessage>
                                                            </Typography>
                                                            <Typography
                                                                id={`datetime-rate-${dateIndex}`}
                                                                variant="subtitle1"
                                                                className={
                                                                    classes.date
                                                                }
                                                            >
                                                                {firstRateEntryAtFirstCategory.deliveryDateRange.earliestEstimatedDate.format(
                                                                    "ddd, MMM Do"
                                                                ) +
                                                                    " - " +
                                                                    firstRateEntryAtFirstCategory.deliveryDateRange.latestEstimatedDate.format(
                                                                        "ddd, MMM Do"
                                                                    )}
                                                            </Typography>
                                                        </Grid>
                                                    ) : deliveryDate ? (
                                                        <Typography
                                                            id={`datetime-rate-${dateIndex}`}
                                                            variant="subtitle1"
                                                            className={
                                                                classes.date
                                                            }
                                                        >
                                                            {deliveryDate.format(
                                                                "ddd, MMM Do"
                                                            )}
                                                        </Typography>
                                                    ) : (
                                                        <Typography className="left-padding call-carrier">
                                                            {
                                                                "Call carrier for delivery date"
                                                            }
                                                        </Typography>
                                                    )}
                                                    {dateIndex === 0 && (
                                                        <Typography
                                                            id="rateResults__fastest"
                                                            color="secondary"
                                                            variant="subtitle1"
                                                        >
                                                            <FormattedMessage
                                                                id="rateResults__fastest"
                                                                defaultMessage="Fastest"
                                                            />
                                                        </Typography>
                                                    )}
                                                    {priorityPlusChip &&
                                                        firstPriorityRate &&
                                                        !expired &&
                                                        !shipped && (
                                                            <FastestPriorityChip
                                                                rate={
                                                                    firstPriorityRate
                                                                }
                                                                cpg={cpg}
                                                            />
                                                        )}
                                                </Grid>
                                                <Grid item container xs={9}>
                                                    {Object.keys(
                                                        rateBuckets[dateGroup]
                                                    )
                                                        .sort(
                                                            (a, b) =>
                                                                b.startsWith(
                                                                    "FX"
                                                                ) -
                                                                a.startsWith(
                                                                    "FX"
                                                                )
                                                        )
                                                        .map(
                                                            (
                                                                categoryKey,
                                                                carrierIndex
                                                            ) => (
                                                                <Grid
                                                                    item
                                                                    container
                                                                    key={
                                                                        categoryKey
                                                                    }
                                                                    className={
                                                                        carrierIndex ===
                                                                        0
                                                                            ? classes.carrierGroup__first
                                                                            : classes.carrierGroup
                                                                    }
                                                                >
                                                                    <Grid
                                                                        item
                                                                        container
                                                                        xs={4}
                                                                        alignContent="flex-start"
                                                                    >
                                                                        <CarrierLogo
                                                                            carrierCode={
                                                                                rateBuckets[
                                                                                    dateGroup
                                                                                ][
                                                                                    categoryKey
                                                                                ][0]
                                                                                    ?.carrierCode
                                                                            }
                                                                            mode={
                                                                                rateBuckets[
                                                                                    dateGroup
                                                                                ][
                                                                                    categoryKey
                                                                                ][0]
                                                                                    ?.mode
                                                                            }
                                                                            serviceLevel={
                                                                                rateBuckets[
                                                                                    dateGroup
                                                                                ][
                                                                                    categoryKey
                                                                                ][0]
                                                                                    ?.serviceLevel
                                                                                    ?.description
                                                                            }
                                                                            largeText
                                                                            isFXFD={categoryKey.includes(
                                                                                "LTL_DIRECT"
                                                                            )}
                                                                            containsCombinedOtherRatesAndFedexOceanRates={
                                                                                rateBuckets[
                                                                                    dateGroup
                                                                                ][
                                                                                    firstBucketCategoryKey
                                                                                ].find(
                                                                                    x =>
                                                                                        containsCombinedOtherRatesAndFedexOceanRates(
                                                                                            x
                                                                                        )
                                                                                ) !==
                                                                                undefined
                                                                            }
                                                                        />
                                                                    </Grid>

                                                                    {singleWorkflowLtlAndLtlDirect &&
                                                                    categoryKey.includes(
                                                                        "LTL_DIRECT"
                                                                    ) ? (
                                                                        <FXFDRates
                                                                            rateBuckets={processFXFDRates(
                                                                                rateBuckets[
                                                                                    dateGroup
                                                                                ][
                                                                                    categoryKey
                                                                                ],
                                                                                destinationCountry
                                                                            )}
                                                                            classes={
                                                                                classes
                                                                            }
                                                                            isSharing={
                                                                                isSharing
                                                                            }
                                                                            selections={
                                                                                selections
                                                                            }
                                                                            setIsSelected={
                                                                                setIsSelected
                                                                            }
                                                                            selectRate={
                                                                                selectRate
                                                                            }
                                                                            canViewRateAmounts={
                                                                                canViewRateAmounts
                                                                            }
                                                                            expired={
                                                                                expired
                                                                            }
                                                                            shippedRateQuote={
                                                                                shippedRateQuote
                                                                            }
                                                                            shipped={
                                                                                shipped
                                                                            }
                                                                            paymentType={
                                                                                paymentType
                                                                            }
                                                                            isSingleWorkflow={
                                                                                true
                                                                            }
                                                                        ></FXFDRates>
                                                                    ) : (
                                                                        <Grid
                                                                            item
                                                                            container
                                                                            xs={
                                                                                8
                                                                            }
                                                                        >
                                                                            {rateBuckets[
                                                                                dateGroup
                                                                            ][
                                                                                categoryKey
                                                                            ].map(
                                                                                (
                                                                                    rate,
                                                                                    index
                                                                                ) => {
                                                                                    const isShippedRate =
                                                                                        shippedRateQuote?._id ===
                                                                                        rate._id
                                                                                    return (
                                                                                        <RateRow
                                                                                            key={
                                                                                                rate?._id
                                                                                            }
                                                                                            category={
                                                                                                GA_CATEGORY
                                                                                            }
                                                                                            trackGAEvent={
                                                                                                trackGA
                                                                                            }
                                                                                            rate={
                                                                                                rate
                                                                                            }
                                                                                            expired={
                                                                                                expired
                                                                                            }
                                                                                            guaranteedList={
                                                                                                guaranteedList
                                                                                            }
                                                                                            canViewRateAmounts={
                                                                                                canViewRateAmounts
                                                                                            }
                                                                                            isSharing={
                                                                                                isSharing
                                                                                            }
                                                                                            setIsSelected={
                                                                                                setIsSelected
                                                                                            }
                                                                                            selections={
                                                                                                selections
                                                                                            }
                                                                                            selectRate={
                                                                                                selectRate
                                                                                            }
                                                                                            index={`${dateIndex}-${index}`}
                                                                                            isShippedRate={
                                                                                                isShippedRate
                                                                                            }
                                                                                            shipped={
                                                                                                shipped
                                                                                            }
                                                                                            paymentType={
                                                                                                paymentType
                                                                                            }
                                                                                            isOffshoreCalculatedFedexRate={isOffshoreCalculatedFedexRate(
                                                                                                origin
                                                                                                    .shippingAddress
                                                                                                    ?.address
                                                                                                    ?.country,
                                                                                                destination
                                                                                                    .shippingAddress
                                                                                                    ?.address
                                                                                                    ?.country,
                                                                                                origin
                                                                                                    .shippingAddress
                                                                                                    ?.address
                                                                                                    ?.state,
                                                                                                destination
                                                                                                    .shippingAddress
                                                                                                    ?.address
                                                                                                    ?.state,
                                                                                                rate,
                                                                                                domesticOffshoreRating,
                                                                                                domesticOffshoreAkRating,
                                                                                                domesticOffshoreHiRating
                                                                                            )}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            )
                                                        )}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else if (currentStep > 4) {
        return (
            <RatesSummary
                handleEdit={handleEdit}
                formValues={formValues}
                canViewRateAmounts={canViewRateAmounts}
            />
        )
    }
}
