import React from "react"
import AppBar from "@material-ui/core/AppBar"
import { bulkCreateShipments } from "../../../actions/book-shipment-requests"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import { useBookedShipmentContext } from "../../../context/providers/BookedShipmentProvider"
import { useOrdersContext } from "../../../context/providers/OrdersProvider"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { addShipment } from "../../../actions/track"
import { useDispatch } from "react-redux"
import { Box } from "@material-ui/core"
import { isFedExCarrier } from "../../../misc"

const useStyles = makeStyles(theme => ({
    appBar: {
        top: "auto",
        bottom: 0,
        width: "100%",
    },
    submit: {
        marginLeft: "auto",
    },
}))

const BookOrdersFooter = ({
    setSelectedRates,
    setOpenPickUpModel,
    setSuccessBookedShipments,
    selectedRates,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { openSnackbar } = useSnackbarContext()
    const { ordersToBook, setOrdersToBook } = useBookedShipmentContext()
    const {
        isBookingOrder,
        setIsBookingOrder,
        markBulkOrdersAsUnfulfilled,
        setOrdersForPickup,
        handleOrderStatusChange,
        fetchOrdersSummaryData,
    } = useOrdersContext()

    const getBookRequest = async payload => {
        try {
            const result = await bulkCreateShipments(payload)
            dispatch(addShipment(result))
            return result
        } catch (error) {
            if (error?.message) {
                throw error?.message
            } else {
                throw error
            }
        }
    }

    const bookShipments = async payloads => {
        //filtering only fedex rates, p44 were discarded
        const payloadMapToArray = Array.from(payloads.entries())
            .map(order => order[1])
            .filter(rate => isFedExCarrier(rate?.selectedRate?.carrierCode))

        try {
            const shipmentResults = await Promise.all(
                payloadMapToArray.map(async order => {
                    try {
                        const result = await getBookRequest(order)
                        return {
                            result,
                            successful: true,
                        }
                    } catch (err) {
                        if (
                            err === "Pickup date cannot be in the past" ||
                            err ===
                                "Cannot create shipment from the selected rate"
                        ) {
                            await markBulkOrdersAsUnfulfilled(
                                Array.of(order?.id)
                            )
                        }
                        return {
                            error: err,
                            successful: false,
                        }
                    }
                })
            )

            return shipmentResults
        } catch (err) {
            throw err
        }
    }

    const onBookOrders = async payloads => {
        setIsBookingOrder(true)

        let p44Rates = Array.from(payloads.entries())
            .map(order => order[1])
            .filter(rate => !isFedExCarrier(rate?.selectedRate?.carrierCode))

        let shipmentResults
        if (payloads.size > 0) {
            try {
                shipmentResults = await bookShipments(payloads)
                await fetchOrdersSummaryData({})
            } catch (err) {
                console.error("Error in onBookShipments:", err?.message || err)
            } finally {
                if (
                    shipmentResults.some(result => result?.successful) &&
                    shipmentResults.some(result => !result?.successful)
                ) {
                    await fetchOrdersSummaryData({})
                    handleOrderStatusChange("closed")
                    setOpenPickUpModel(true)
                    openSnackbar(
                        "info",
                        "Your shipment(s) booked successfully, but some shipments failed and can be viewed in Action Required."
                    )
                } else if (shipmentResults.some(result => result?.successful)) {
                    await fetchOrdersSummaryData({})
                    handleOrderStatusChange("closed")
                    openSnackbar(
                        "success",
                        `Your shipment(s) successfully booked.`
                    )
                    setOpenPickUpModel(true)
                } else if (p44Rates.length === 0) {
                    await fetchOrdersSummaryData({})
                    handleOrderStatusChange("actionRequired")
                    openSnackbar(
                        "error",
                        `An error occurred while booking shipment(s). Order(s) moved to "Action Required".`
                    )
                } else if (
                    p44Rates.length > 0 &&
                    (shipmentResults.some(result => !result?.successful) ||
                        shipmentResults.length === 0)
                ) {
                    await fetchOrdersSummaryData({})
                    setOpenPickUpModel(true)
                    handleOrderStatusChange("closed")
                }
                setSelectedRates(new Map())
                setOrdersForPickup(
                    Array.from(ordersToBook.values()).map(value => value.id)
                )
                setOrdersToBook(new Map())
                setIsBookingOrder(false)
                setSuccessBookedShipments([
                    ...shipmentResults
                        .filter(result => result?.successful)
                        .map(result => result?.result),
                    ...p44Rates,
                ])
            }
        } else {
            openSnackbar("error", "Error booking shipment.")
            setOrdersForPickup([])
            setSelectedRates(new Map())
            setOrdersToBook(new Map())
            setIsBookingOrder(false)
        }
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            width: "100%",
                        }}
                    >
                        <Box
                            item
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            className={classes.submit}
                        >
                            <Button
                                id="book__bookShipment"
                                variant="contained"
                                color="secondary"
                                disabled={isBookingOrder}
                                onClick={() => onBookOrders(ordersToBook)}
                            >
                                <FormattedMessage
                                    id="orders.book.bulkBook"
                                    values={{
                                        count: selectedRates.size,
                                        s: selectedRates.size > 1 ? "s" : "",
                                    }}
                                    defaultMessage="Book {count} Order{s}"
                                />
                            </Button>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    )
}

export default BookOrdersFooter
