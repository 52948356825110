export const styles = theme => ({
    item__subtitle: {
        color: "gray",
        fontSize: "0.75rem",
    },
    itemPage__container: {
        padding: "3% 5% 5% 5%",
        minWidth: "898px",
    },

    itemPage__title: {
        marginBottom: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    location__container: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    checkbox__container: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    divider__container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    button__rounded: {
        margin: "10px 5px",
    },
    input__number: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    panel__summary: {
        padding: "3px",
        margin: "5px",
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
        padding: "15px",
    },
    textFieldDropdown: {
        marginTop: "-2px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        paddingRight: "20px",
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
})
