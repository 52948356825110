import React, { useState, createContext, useContext, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { goFetch } from "../../http"
import { useSnackbarContext } from "./snackbarProvider"
import { useGAContext } from "./GoogleAnalyticsProvider"

export const ItemsContext = createContext()

export const useItemsContext = () => {
    const itemsContext = useContext(ItemsContext)
    if (!itemsContext) {
        throw new Error("Cannot use items context ouside of ItemsProvider")
    }
    return itemsContext
}

export default function ItemsProvider({ children }) {
    const [itemsList, setItemsList] = useState([])
    const [itemsCount, setItemsCount] = useState(0)
    const [itemEditId, setItemEditId] = useState(null)
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [addingItem, setAddingItem] = useState(false)
    const [measurementSystem, setMeasurementSystem] = useState("IMPERIAL")
    const [isHazmat, setIsHazmat] = useState(false)

    const { openSnackbar } = useSnackbarContext()
    const { logGAEvent } = useGAContext()

    const getItemList = async () => {
        setLoading(true)
        try {
            const { data } = await goFetch(
                "/item",
                { validErrorCodes: [404] },
                true
            )
            setItemsList(data)
            setItemsCount(data.length)
        } catch (error) {
            openSnackbar(
                "success",
                <FormattedMessage
                    id="item.fetch.success"
                    defaultMessage="There was an error fetching items."
                />,
                2000
            )
        }
        setLoading(false)
    }
    const saveItem = async data => {
        setLoading(true)
        logGAEvent("Items", `Item Added`)
        try {
            const response = await goFetch(
                "/item",
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data,
                },
                true
            )
            setItemsCount(itemsCount + 1)
            setItemsList([response.data, ...itemsList])

            openSnackbar(
                "success",
                <FormattedMessage
                    id="items.add.success"
                    defaultMessage="{name} added"
                    values={{ name: response.data.description }}
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="items.add.error"
                    defaultMessage="Error adding {name}"
                    values={{ name: error.data.description }}
                />,
                4000
            )
        }
        setLoading(false)
    }

    const updateItem = async (data, id) => {
        setLoading(true)
        logGAEvent("Items", `Item Updated`)
        try {
            const response = await goFetch(
                `/item/${id}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        ...data,
                        _id: id,
                    },
                },
                true
            )
            setItemsCount(itemsCount + 1)
            setItemsList(replaceItemById(response?.data, id))
            openSnackbar(
                "success",
                <FormattedMessage
                    id="items.edit.success"
                    defaultMessage="{name} updated"
                    values={{ name: response?.data?.description }}
                />,
                4000
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="items.edit.error"
                    defaultMessage="Error updating item"
                />,
                4000
            )
        } finally {
            setLoading(false)
        }
    }

    const replaceItemById = (newItem, replacementId) => {
        const index = itemsList.findIndex(item => item._id === replacementId)
        if (index !== -1) {
            itemsList[index] = newItem
            return itemsList
        } else {
            throw Error("Error updating data")
        }
    }

    const deleteItem = async itemToDelete => {
        try {
            setLoading(true)
            await goFetch(
                `/item/${itemToDelete._id}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            logGAEvent("Items", "Item Deleted")

            openSnackbar(
                "success",
                <FormattedMessage
                    id="item.delete.delete.success"
                    defaultMessage="{field} has been removed from your item list."
                    values={{ field: itemToDelete?.description }}
                />,
                2000
            )
            setItemsList(
                itemsList.filter(item => itemToDelete._id !== item._id)
            )
            setItemsCount(itemsCount - 1)
        } catch (e) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="item.delete.delete.failure"
                    defaultMessage="Failure deleting item {field}"
                    values={{ field: itemToDelete?.description }}
                />,
                2000
            )
        }
        setLoading(false)
    }

    const contextData = {
        itemsList,
        loading,
        deleteItem,
        getItemList,
        itemsCount,
        addingItem,
        setAddingItem,
        saveItem,
        updateItem,
        measurementSystem,
        setMeasurementSystem,
        isHazmat,
        setIsHazmat,
        itemEditId,
        setItemEditId,
        editing,
        setEditing,
    }

    return (
        <ItemsContext.Provider value={contextData}>
            {children}
        </ItemsContext.Provider>
    )
}
