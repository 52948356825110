import ReactGA from "react-ga4"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { get } from "lodash"
import {
    initApp,
    changeLock,
    onAuthenticated,
    onTokenParsed,
    getTokenExpirationDate,
    loggedIn,
    authorizationError,
    isParseParam,
    logOut,
    onRefreshToken,
} from "../actions/authorization"
import "../landingPage.css"
import "../magnific-popup.css"
import FormSelectLanguage from "./form/form-select-language"
import { preferenceLanguageOptions } from "./constants/preferenceLanguageOptions"
import { FormattedMessage } from "react-intl"
import { withLDConsumer } from "launchdarkly-react-client-sdk"
import moment from "moment"

const englishVideos = {
    signingUpAndAccessingLTLSelect: "kMTxBvYZ4c8",
    dashboardAndMainNavigation: "FKn131Zs3jk",
    addLocationUsersAndCarriers: "Vw9axNk5xrc",
    howToBookAShipment: "CfTGxQZTygA",
    fedExFreightDirect: "KDO2LHrv_Ao",
}

const frenchVideos = {
    signingUpAndAccessingLTLSelect: "JnJPowh4Ff8",
    dashboardAndMainNavigation: "uSxoT_y9urU",
    addLocationUsersAndCarriers: "TARrcfwyW48",
    howToBookAShipment: "kO5XKRp8e2Q",
    fedExFreightDirect: "4DmS5kbk4_I",
}

const spanishVideos = {
    signingUpAndAccessingLTLSelect: "R6PFGrQ2WiU",
    dashboardAndMainNavigation: "XpKZVZP901g",
    addLocationUsersAndCarriers: "ZBhyqYvmeL8",
    howToBookAShipment: "Kwmx0ycB9Cs",
    fedExFreightDirect: "hzyYhswERts",
}

class Authorization extends Component {
    state = {
        language: "en-us",
        modalOpen: false,
        videos: englishVideos,
    }

    constructor(props) {
        super(props)
        this.demosContainerRef = React.createRef()
    }

    componentDidMount() {
        const path = get(this.props, "location.pathname")
        this.initialize()
        this.props.doInitApp(this.context.router, path)
        this.parseToken(path)
    }

    scrollToVideos() {
        this.demosContainerRef.current.scrollIntoView({
            behaviour: "smooth",
            block: "start",
            inline: "center",
        })
    }

    componentWillUnmount() {
        this.setTokenRefreshCallback()
    }

    componentWillReceiveProps(newProps) {
        const {
            authorization: {
                message: newMessage,
                isFinished: newIsFinished,
            } = {},
            shouldNotDisplay: newShouldNotDisplay,
        } = newProps
        const {
            authorization: { message, isFinished } = {},
            shouldNotDisplay,
        } = this.props

        if (!newShouldNotDisplay && shouldNotDisplay) {
            ReactGA.event("Landing Page", "Landing Page Viewed")
        }
        if (newShouldNotDisplay !== shouldNotDisplay) {
            this.initialize()
        }

        if (isFinished !== newIsFinished) {
            this.setTokenRefreshCallback()
            if (newIsFinished) {
                this.lock.hide()
            } else {
                this.lock.show()
            }
        }

        if (newMessage !== message) {
            this.updateMessage(newMessage)
        }
        const oldPath = get(this.props, "location.pathname")
        const newPath = get(newProps, "location.pathname")

        if (oldPath !== newPath) this.parseToken(newPath)
    }

    parseToken(token) {
        if (get(this.props, "authorization.tokenParsed")) return
        this.lock.resumeAuth(token.substring(1), (error, hash) => {
            if (hash) this.props.onTokenParsed()
        })
    }

    updateMessage(text) {
        return text
            ? this.lock.show({
                  flashMessage: { type: "error", text },
              })
            : this.lock.show()
    }

    setTokenRefreshCallback() {
        if (this.refreshCallback) {
            clearTimeout(this.refreshCallback)
            this.refreshCallback = null
        }
        const expiryDate = getTokenExpirationDate(loggedIn())
        if (expiryDate) {
            const expFromNow = expiryDate.getTime() - Date.now()
            this.refreshCallback = setTimeout(() => {
                this.lock.checkSession({}, (err, authResult) => {
                    if (err) return this.props.logout()
                    onRefreshToken(authResult)
                    this.setTokenRefreshCallback()
                })
            }, expFromNow)
        }
    }

    initialize() {
        const { language } = this.props
        this.lock = changeLock(language)
        this.lock.on("authenticated", this.props.onAuthenticated)
        this.lock.on("authorization_error", this.props.onAuthError)
        this.lock.on("unrecoverable_error", this.props.onAuthError)
        this.lock.show()
    }

    changeLock(language) {
        this.lock.hide()
        this.lock = changeLock(language)
        this.lock.on("authenticated", this.props.onAuthenticated)
        this.lock.on("authorization_error", this.props.onAuthError)
        this.lock.on("unrecoverable_error", this.props.onAuthError)
        this.lock.show()
    }

    changeLanguage(e) {
        const { setLanguage, language } = this.props
        switch (e.target?.value) {
            case "en-us":
                this.setState({ videos: englishVideos })
                break
            case "fr-ca":
                this.setState({ videos: frenchVideos })
                break
            case "es-mx":
                this.setState({ videos: spanishVideos })
                break
            default:
                break
        }
        setLanguage(e.target?.value)
        localStorage.setItem("language", e.target?.value)
        if (e.target.value !== language) {
            this.changeLock(e.target.value)
        }
    }

    closeModal() {
        this.setState({ modalOpen: false })
    }

    render() {
        const { shouldNotDisplay, language } = this.props

        document.addEventListener("keydown", e => {
            if (e.key === "Escape" && this.state.modalOpen) {
                this.closeModal()
            }
        })

        if (shouldNotDisplay) {
            return (
                <div className="main__container" style={{ display: "none" }}>
                    <div className="login-box">
                        <div id="auth0-lock-container-en" />
                        <div id="auth0-lock-container-fr" />
                        <div id="auth0-lock-container-es" />
                    </div>
                </div>
            )
        }

        return (
            <div
                className={
                    this.state.modalOpen
                        ? "main__cotainer__modalOpen"
                        : "main__container"
                }
                style={{ display: "block" }}
                id="landingPageContainer"
                onClick={() => {
                    if (this.state.modalOpen) {
                        this.closeModal()
                    }
                }}
            >
                {this.state.modalOpen ? (
                    <div id="watch-overview-modal" className="white-popup ">
                        <div
                            style={{
                                position: "relative",
                                display: "block",
                                maxWidth: "960px",
                                margin: "5% auto",
                            }}
                        >
                            <span
                                title="Close"
                                onClick={() => {
                                    this.closeModal()
                                }}
                                className="close-modal"
                                id="close-modal-btn"
                            >
                                <h2 style={{ textAlign: "center" }}>&times;</h2>
                            </span>
                            <div
                                style={{
                                    paddingTop: "56.25%",
                                }}
                            >
                                <iframe
                                    src={`https://www.youtube.com/embed/${this.state.videos?.signingUpAndAccessingLTLSelect}?rel=0&showinfo=0&controls=0&autoplay=1`}
                                    allowFullScreen
                                    title="overview"
                                    allow="autoplay; encrypted-media"
                                    style={{
                                        border: "none",
                                        position: "absolute",
                                        top: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="language__container">
                    <FormSelectLanguage
                        input={{ name: "language" }}
                        label={[
                            "* ",
                            <FormattedMessage
                                id="userPreferences.generalInformation__language"
                                defaultMessage="Language"
                            />,
                        ]}
                        options={preferenceLanguageOptions}
                        value={language}
                        MenuProps={{
                            onOpen: this.testF,
                        }}
                        onChange={e => {
                            this.changeLanguage(e)
                        }}
                    />
                </div>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    href="https://fonts.googleapis.com/css?family=Roboto:100,300,400"
                    rel="stylesheet"
                />
                <link href="../landingPage.css" rel="stylesheet" />
                <link href="../magnific-popup.css" rel="stylesheet" />
                <div>
                    <header id="header">
                        <div className="header-cont">
                            <div className="header-content container">
                                <div className="header-copy">
                                    <h1 className="header-text">
                                        LTL{" "}
                                        <span className="select">Select</span>
                                    </h1>
                                    <h2>
                                        <FormattedMessage
                                            id="landingPage__1"
                                            defaultMessage="Getting freight quotes from multiple
                                        carriers can be a real hassle. But it
                                        doesn’t have to be. With LTL Select,
                                        just enter your information once to
                                        compare all of your freight shipping
                                        rates quickly and easily."
                                        />
                                    </h2>
                                    {/* <a href="#icons" class="track" data-emacategory="In-Page Navigation" data-emalabel="Get Started | Learn More">Check it out</a> */}
                                    {/* a href="http://www.youtube.com/watch?v=0O2aH4XLbto" class="button popup-youtube">Watch Overview</a */}
                                    <div
                                        href="#watch-overview"
                                        className="button open-popup-link"
                                        id="watch-overview-button"
                                        onClick={() => {
                                            this.setState({ modalOpen: true })
                                            ReactGA.event(
                                                "Landing Page",
                                                "Watch Overview Click"
                                            )
                                        }}
                                    >
                                        <FormattedMessage
                                            id="landingPage__2"
                                            defaultMessage="Sign Up Tutorial"
                                        />
                                    </div>
                                    <div
                                        href="#"
                                        id="watch-demo-button"
                                        className="button"
                                        onClick={() => {
                                            if (
                                                this.demosContainerRef?.current
                                            ) {
                                                this.scrollToVideos()
                                                ReactGA.event(
                                                    "Landing Page",
                                                    "Watch Demos Clicked"
                                                )
                                            }
                                        }}
                                    >
                                        <FormattedMessage
                                            id="landingPage__3"
                                            defaultMessage="Watch Demos"
                                        />
                                    </div>
                                </div>
                                <div className="login-box">
                                    <div id="auth0-lock-container-en" />
                                    <div id="auth0-lock-container-fr" />
                                    <div id="auth0-lock-container-es" />
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="icon-cont container" id="icons">
                        <h3>
                            <FormattedMessage
                                id="landingPage__4"
                                defaultMessage="LTL Select offers you:"
                            />
                        </h3>
                        <div className="icon-list">
                            <div className="icon-container">
                                <div className="icon1">
                                    <img src="static/Icon1.jpg" alt="clock" />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__5"
                                        defaultMessage="A Free TMS"
                                    />
                                </p>
                                <p className="sub-p">
                                    <FormattedMessage
                                        id="landingPage__6"
                                        defaultMessage="Investing in a transportation management
                                        system can be expensive, but not with LTL
                                        Select. This TMS is completely online and
                                        free to use."
                                    />
                                </p>
                            </div>
                            <div className="icon-container">
                                <div className="icon1">
                                    <img src="static/Icon2.jpg" alt="clock" />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__7"
                                        defaultMessage="Speed and Flexibility"
                                    />
                                </p>
                                <p className="sub-p">
                                    <FormattedMessage
                                        id="landingPage__8"
                                        defaultMessage="Not only will you get freight quotes from
                                        multiple carriers, you can compare by
                                        transit times — to keep your product and
                                        business moving forward."
                                    />
                                </p>
                            </div>
                            <div className="icon-container">
                                <div className="icon1">
                                    <img src="static/Icon3.jpg" alt="clock" />
                                </div>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__9"
                                        defaultMessage="Smart LTL Shipping"
                                    />
                                </p>
                                <p className="sub-p">
                                    <FormattedMessage
                                        id="landingPage__10"
                                        defaultMessage="Enter your shipment information once to
                                        instantly compare LTL freight quotes side by
                                        side."
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="truckload-cont">
                        <div className="container">
                            <div className="truck-img">
                                <img
                                    src="static/features.jpg"
                                    alt="LTL screenshot"
                                />
                            </div>
                            <div className="truckload-copy">
                                <h3>
                                    <FormattedMessage
                                        id="landingPage__11"
                                        defaultMessage="Loads of features"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__12"
                                        defaultMessage="Here are some of the ways LTL Select can
                                        help you improve business performance and
                                        your customer experience:"
                                    />
                                </p>
                                <ul>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__13"
                                            defaultMessage="Create a bill of lading with as few as
                                            two clicks"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__14"
                                            defaultMessage="Compare LTL shipping rates from multiple
                                            carriers"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__15"
                                            defaultMessage="Get rates based on fastest transit times"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__16"
                                            defaultMessage="Share quotes via email"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__17"
                                            defaultMessage="Add upcharges to shipments"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__18"
                                            defaultMessage="Get instant shipping and tracking
                                            confirmation"
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id="landingPage__19"
                                            defaultMessage="Make repeat shipments easy with “Ship
                                            Again” feature"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        className="vid-grid"
                        ref={this.demosContainerRef}
                        id="watch-videos"
                    >
                        {/* eslint-disable-next-line */}
                        <a name="vid-grid" />
                        <h3 className="title">
                            <FormattedMessage
                                id="landingPage__20"
                                defaultMessage="Getting started with LTL Select"
                            />
                        </h3>
                        <div className="row">
                            <div className="column">
                                <div
                                    style={{
                                        position: "relative",
                                        display: "block",
                                        maxWidth: "960px",
                                    }}
                                >
                                    <div style={{ paddingTop: "56.25%" }}>
                                        <iframe
                                            src={`https://www.youtube.com/embed/${this.state.videos?.dashboardAndMainNavigation}?rel=0&showinfo=0&controls=0&modestbranding=1`}
                                            allowFullScreen
                                            allow="encrypted-media"
                                            style={{
                                                border: "none",
                                                position: "absolute",
                                                top: "0px",
                                                right: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            title="Dashboard and Main Navigation"
                                        />
                                    </div>
                                </div>
                                <h3>
                                    <FormattedMessage
                                        id="landingPage__21"
                                        defaultMessage="Dashboard &amp; Main Navigation"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__22"
                                        defaultMessage="How to take a quick look at all of your shipments
                                        and their statuses, all in one place."
                                    />
                                </p>
                            </div>
                            <div className="column">
                                <div
                                    style={{
                                        position: "relative",
                                        display: "block",
                                        maxWidth: "960px",
                                    }}
                                >
                                    <div style={{ paddingTop: "56.25%" }}>
                                        <iframe
                                            src={`https://www.youtube.com/embed/${this.state.videos?.addLocationUsersAndCarriers}?rel=0&showinfo=0&controls=0`}
                                            allowFullScreen
                                            allow="encrypted-media"
                                            style={{
                                                border: "none",
                                                position: "absolute",
                                                top: "0px",
                                                right: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            title="Add Location, Users & Carriers"
                                        />
                                    </div>
                                </div>
                                <h3>
                                    <FormattedMessage
                                        id="landingPage__23"
                                        defaultMessage="Add Location, Users &amp; Carriers"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__24"
                                        defaultMessage="LTL Select makes it easy to book shipments
                                        from multiple locations, share your account
                                        with colleagues and ship with multiple
                                        carriers."
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="column">
                                <div
                                    style={{
                                        position: "relative",
                                        display: "block",
                                        maxWidth: "960px",
                                    }}
                                >
                                    <div style={{ paddingTop: "56.25%" }}>
                                        <iframe
                                            src={`https://www.youtube.com/embed/${this.state.videos?.howToBookAShipment}?rel=0&showinfo=0&controls=0`}
                                            allowFullScreen
                                            allow="encrypted-media"
                                            style={{
                                                border: "none",
                                                position: "absolute",
                                                top: "0px",
                                                right: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            title="How To Book A Shipment"
                                        />
                                    </div>
                                </div>
                                <h3>
                                    <FormattedMessage
                                        id="landingPage__25"
                                        defaultMessage="How To Book A Shipment"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__26"
                                        defaultMessage="Get a step-by-step look at how to quickly
                                        and easily get freight quotes from multiple
                                        carriers and create a shipment."
                                    />
                                </p>
                            </div>
                            <div className="column">
                                <div
                                    style={{
                                        position: "relative",
                                        display: "block",
                                        maxWidth: "960px",
                                    }}
                                >
                                    <div style={{ paddingTop: "56.25%" }}>
                                        <iframe
                                            src={`https://www.youtube.com/embed/${this.state.videos?.fedExFreightDirect}?rel=0&showinfo=0&controls=0`}
                                            allowFullScreen
                                            allow="encrypted-media"
                                            style={{
                                                border: "none",
                                                position: "absolute",
                                                top: "0px",
                                                right: "0px",
                                                bottom: "0px",
                                                left: "0px",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            title="FedEx Freight Direct"
                                        />
                                    </div>
                                </div>
                                <h3>
                                    <FormattedMessage
                                        id="landingPage__27"
                                        defaultMessage="FedEx Freight Direct"
                                    />
                                </h3>
                                <p>
                                    <FormattedMessage
                                        id="landingPage__28"
                                        defaultMessage="Take the next step in great service. Right through the front door. Learn how to book FedEx Direct on LTL Select for residential and business freight delivery."
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container center-copy about-ltl">
                        <h3>
                            <FormattedMessage
                                id="landingPage__29"
                                defaultMessage="About LTL Select"
                            />
                        </h3>
                        <p>
                            <FormattedMessage
                                id="landingPage__30"
                                defaultMessage="This intuitive platform connects shippers to LTL
                                carriers. It is provided by FedEx, but run by a
                                third party. FedEx does not see your accounts or
                                transactions with any other carriers."
                            />
                        </p>
                    </div>
                    <footer className="site-footer">
                        <div className="container">
                            <h3 className="footer-text">
                                LTL <span className="select">Select</span>
                            </h3>
                            <p>
                                © {moment(new Date()).format("YYYY")} FedEx{" "}
                                <span> | </span>{" "}
                                <a
                                    href="https://www.ltlselect.com/static/LTLselect-T&C-2017_12_06_v01.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FormattedMessage
                                        id="landingPage__31"
                                        defaultMessage="Privacy Policy"
                                    />
                                </a>{" "}
                                <span> | </span>{" "}
                                <a
                                    href="https://www.ltlselect.com/static/LTLselect-T&C-2017_12_06_v01.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FormattedMessage
                                        id="landingPage__32"
                                        defaultMessage="Terms &amp; Conditions"
                                    />
                                </a>
                            </p>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

Authorization.propTypes = {
    authorization: PropTypes.object.isRequired,
    // doInitAuth: PropTypes.func.isRequired,
    // onShow: PropTypes.func.isRequired,
    onAuthenticated: PropTypes.func.isRequired,
    onAuthError: PropTypes.func.isRequired,
    onTokenParsed: PropTypes.func.isRequired,
}

// Authorization.contextTypes = {
//     router: PropTypes.object.isRequired,
// }

const mapStateToProps = ({ authorization, shareStatus }, { location }) => ({
    authorization,
    shouldNotDisplay:
        isParseParam(location.pathname) ||
        loggedIn() ||
        location?.pathname?.startsWith("/terms") ||
        !authorization?.initFinished ||
        authorization?.tokenParsed ||
        authorization?.isFinished,
})

const mapDispatchToProps = (dispatch, props) => ({
    doInitApp: (router, path) => dispatch(initApp(router, path)),
    onAuthenticated: authResult =>
        dispatch(onAuthenticated(authResult, props?.ldClient)),
    onAuthError: error => dispatch(authorizationError(error)),
    onTokenParsed: () => dispatch(onTokenParsed()),
    logout: () => dispatch(logOut()),
})

export const AuthorizationContainer = withLDConsumer()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Authorization))
)
