import React, { useState } from "react"
import PropTypes from "prop-types"
import {
    withStyles,
    Box,
    Typography,
    IconButton,
    Paper,
} from "@material-ui/core"
import { styles } from "./styles"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Create"
import { FormattedMessage } from "react-intl"
import { allPackageTypeOptions } from "./constants"
import { useItemsContext } from "../../context/providers/ItemsProvider"
import { useGAContext } from "../../context/providers/GoogleAnalyticsProvider"

const LineItem = ({ item, classes, onDeleteItem }) => {
    const [hovering, setHovering] = useState(false)
    const { setItemEditId, setEditing } = useItemsContext()
    const { logGAEvent } = useGAContext()

    const packageType =
        allPackageTypeOptions.find(i => i.value === item?.packageType)?.label ||
        {}

    const packageTypeTranslation = {
        id: packageType?.id,
        defaultMessage: packageType?.defaultMessage,
    }

    return (
        <Box
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <Box
                component={Paper}
                className={classes.panel__summary}
                sx={{ display: "flex" }}
            >
                <Box sx={{ display: "flex", width: "90%", flexWrap: "wrap" }}>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "32%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.description"
                                defaultMessage="Description"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {(item.description && item.description.length > 60
                                ? `${item.description.substring(0, 60)}...`
                                : item.description) || (
                                <FormattedMessage
                                    id="items__description"
                                    defaultMessage="Description"
                                />
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "12%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.packageType"
                                defaultMessage="Package Type"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {item.packageType ? (
                                <FormattedMessage {...packageTypeTranslation} />
                            ) : (
                                "---"
                            )}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "12%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.freightClass"
                                defaultMessage="Freight Class"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {item.freightClass ? item.freightClass : "---"}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "12%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.dimensions"
                                defaultMessage="Dimensions"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {!item.length || !item.width || !item.height
                                ? "---"
                                : `${item.length}×${item.width}×${item.height}${
                                      item.measurementSystem === "METRIC"
                                          ? " cm"
                                          : " in"
                                  }`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "8%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.weight"
                                defaultMessage="Weight"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {!item.weight
                                ? "---"
                                : `${item.weight} ${
                                      item.measurementSystem === "METRIC"
                                          ? " kg"
                                          : " lb"
                                  }`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "8%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            className={classes.item__subtitle}
                        >
                            <FormattedMessage
                                id="items.pieces"
                                defaultMessage="Pieces"
                            />
                        </Typography>
                        <Typography variant="body2">
                            {item?.pieces ?? "---"}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "8%",
                        }}
                    >
                        {item.nmfc1 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    className={classes.item__subtitle}
                                >
                                    <FormattedMessage
                                        id="items.nmfc"
                                        defaultMessage="NMFC"
                                    />
                                </Typography>
                                <Typography variant="body2">
                                    {item.nmfc1 ?? "---"}
                                    {item.nmfc2 ? `-${item.nmfc2}` : null}
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                    <Box
                        sx={{
                            paddingRight: "2px",
                            width: "8%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                        }}
                    >
                        {item.isHazMat && (
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    className={classes.item__subtitle}
                                >
                                    <FormattedMessage
                                        id="items.hazmat"
                                        defaultMessage="Hazmat"
                                    />
                                </Typography>
                                <Typography variant="body2">
                                    {item.unNumber} {item.hazClass ?? ""}{" "}
                                    {item.pkgGrp ?? ""}{" "}
                                    {item.hazContainer ?? ""}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: "10%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    {hovering && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            onClick={e => e.stopPropagation()}
                        >
                            <IconButton
                                onClick={() => {
                                    logGAEvent("Items", "Edit Icon Click")
                                    setItemEditId(item._id)
                                    setEditing(true)
                                }}
                                size="small"
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={onDeleteItem} size="small">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

LineItem.propTypes = {
    onDeleteItem: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    item: PropTypes.shape({
        freightClass: PropTypes.number,
        weight: PropTypes.number,
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        packageType: PropTypes.string,
        pieces: PropTypes.number,
        handlingUnits: PropTypes.string,
        description: PropTypes.string,
        isDefault: PropTypes.bool,
        sizeLocked: PropTypes.bool,
        nmfc1: PropTypes.string,
        nmfc2: PropTypes.string,
        metadata: PropTypes.object,
        _id: PropTypes.string,
        measurementSystem: PropTypes.string,
        isIndividualWeight: PropTypes.bool,
        freightDirectPieces: PropTypes.arrayOf(
            PropTypes.shape({
                count: PropTypes.number,
                weight: PropTypes.number,
            })
        ),
        count: PropTypes.number,
        isHazMat: PropTypes.bool,
        stackable: PropTypes.bool,
        unNumber: PropTypes.string,
        pkgGrp: PropTypes.string,
        hazClass: PropTypes.string,
        hazContainer: PropTypes.string,
        hazDescription: PropTypes.string,
        currencyCode: PropTypes.string,
        unitPrice: PropTypes.number,
        countryOfManufacture: PropTypes.string,
        harmonizedCode: PropTypes.string,
    }).isRequired,
}

export default withStyles(styles)(LineItem)
