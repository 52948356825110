import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Field, reduxForm } from "redux-form"
import {
    Grid,
    Button,
    withStyles,
    Typography,
    Divider,
    Snackbar,
    Fab,
} from "@material-ui/core"
import {
    updateUser,
    contactSelected,
    setDefaultLocation,
    trackGAEvent,
} from "../../actions/user"
import normalizePhone from "../util/normalizePhone"
import FormField from "../form/form-field"
import FormSelect from "../form/form-select"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isNumeric,
} from "../../actions/validation"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import { userUpdateSuccess } from "../../messages/confirmation-constants"
import { userUpdateError } from "../../messages/error-constants"
import AlertsPreferences from "../alerts/alertsPreferences"
import { combineValidators, composeValidators } from "revalidate"
import { FormattedMessage } from "react-intl"
import { preferenceLanguageOptions } from "../constants/preferenceLanguageOptions"
import { supportedLanguagesCountryMap } from "../../misc"

const GACategory = "UserPreferences"

const styles = theme => ({
    form__itemContainer: {
        padding: theme.spacing(3),
    },
    user__form: {
        margin: 0,
    },
    signup__button: {
        position: "fixed",
        top: 64 - theme.spacing(3),
        right: theme.spacing(10),
        zIndex: 1101,
    },
})

class UserFormPresentation extends Component {
    state = {
        sbOpen: false,
        sbMessage: "",
        sbVariant: "",
    }

    componentWillReceiveProps(newProps) {
        if (!this.props.submitSucceeded && newProps.submitSucceeded) {
            this.setState({
                sbOpen: true,
                sbMessage: userUpdateSuccess,
                sbVariant: "success",
            })
        }
        if (!this.props.submitFailed && newProps.submitFailed) {
            this.setState({
                sbOpen: true,
                sbMessage: userUpdateError,
                sbVariant: "error",
            })
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ sbOpen: false })
    }

    render() {
        const {
            handleUpdate,
            handleSubmit,
            invalid,
            submitting,
            classes,
            error,
            language,
            trackGA,
        } = this.props

        const country = supportedLanguagesCountryMap[language] || "US"

        return (
            <div className={classes.user__form}>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <span>
                                <Typography variant="body2">
                                    {this.state.sbMessage}
                                </Typography>
                            </span>
                        }
                    />
                </Snackbar>
                <form onSubmit={handleSubmit(handleUpdate)}>
                    {error && (
                        <Typography color="error" gutterBottom>
                            {error}
                        </Typography>
                    )}
                    <Grid
                        container
                        spacing={2}
                        className={classes.form__itemContainer}
                    >
                        <Grid item container xs={7}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    <FormattedMessage
                                        id="userPreferences.generalInformation__title"
                                        defaultMessage="General Information"
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="firstname"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__firstName"
                                            defaultMessage="First Name"
                                        />
                                    }
                                    component={FormField}
                                    className={classes.textField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="lastname"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__lastName"
                                            defaultMessage="Last Name"
                                        />
                                    }
                                    component={FormField}
                                    className={classes.textField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="phone_number.phone_number"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__phoneNumber"
                                            defaultMessage="Phone Number"
                                        />
                                    }
                                    component={FormField}
                                    normalize={normalizePhone(country)}
                                    className={classes.textField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="phone_number.extension"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__extension"
                                            defaultMessage="Extension"
                                        />
                                    }
                                    component={FormField}
                                    className={classes.textField}
                                    category={GACategory}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Field
                                    name="email.email_address"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__email"
                                            defaultMessage="Email Address"
                                        />
                                    }
                                    component={FormField}
                                    className={classes.textField}
                                    category={GACategory}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    name="preferences.language"
                                    label={
                                        <FormattedMessage
                                            id="userPreferences.generalInformation__language"
                                            defaultMessage="Language"
                                        />
                                    }
                                    component={FormSelect}
                                    className={classes.textField}
                                    options={preferenceLanguageOptions}
                                    category={GACategory}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <AlertsPreferences
                                chooseWarnings
                                subject="user"
                                gaCategory={GACategory}
                            />
                        </Grid>
                        <Grid item container xs={1} alignItems="flex-end">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid || submitting}
                                onClick={() =>
                                    trackGA(GACategory, "Update Button Click")
                                }
                            >
                                {
                                    <FormattedMessage
                                        id="userPreferences__update"
                                        defaultMessage="Update"
                                    />
                                }
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider />
                </form>
            </div>
        )
    }
}

UserFormPresentation.propTypes = {
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    extension: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    notificationType: PropTypes.string.isRequired,
    address: PropTypes.object.isRequired,
    shippingAddress: PropTypes.object.isRequired,
    pickupName: PropTypes.string.isRequired,
    pickupPhone: PropTypes.string.isRequired,
    pickupExtension: PropTypes.string.isRequired,
    pickupEmail: PropTypes.string.isRequired,
    validation: PropTypes.object.isRequired,
    readyTime: PropTypes.string.isRequired,
    closeTime: PropTypes.string.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    termsVersion: PropTypes.string.isRequired,
    isSignup: PropTypes.bool,
    btnSubmitName: PropTypes.string.isRequired,
    onContactSelect: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
}

UserFormPresentation.defaultProps = {
    isSignup: false,
    mobile: "",
    submitting: false,
    pristine: false,
    invalid: true,
    error: "",
}

const userValidation = combineValidators({
    firstname: isRequired({
        field: {
            id: "userPreferences.generalInformation__firstName",
            defaultMessage: "First Name",
        },
    }),
    lastname: isRequired({
        field: {
            id: "userPreferences.generalInformation__lastName",
            defaultMessage: "Last Name",
        },
    }),
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "userPreferences.generalInformation__email",
                defaultMessage: "Email Address",
            },
        }),
    },
    phone_number: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(undefined, "preferences.language")
        )({
            field: {
                id: "userPreferences.generalInformation__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "userPreferences.generalInformation__extension",
                defaultMessage: "Extension",
            },
        }),
        mobile: countryPhoneValidator(
            undefined,
            "preferences.language"
        )({
            field: {
                id: "userPreferences.generalInformation__mobileNumber",
                defaultMessage: "Mobile Number",
            },
        }),
    },
    "preferences.language": isRequired({
        field: {
            id: "userPreferences.generalInformation__language",
            defaultMessage: "Language",
        },
    }),
})

const mapStateToProps = state => {
    const user = get(state, "user.profile")
    const locations = get(state, "user.profile.locations", [])
    const { locations: _, ...innerUser } = user

    return {
        initialValues: {
            ...innerUser,
            alerts: { user: get(user, "preferences.alerts") },
        },
        locations,
        formValues: {
            language: "preferences.language",
        },
        testprop: 2,
    }
}

const mapDispatchToProps = dispatch => ({
    onContactSelect: contact => dispatch(contactSelected(contact)),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    handleUpdate: values => dispatch(updateUser(values)),
    handleSetDefault: (index, length) =>
        dispatch(setDefaultLocation(index, length)),
})

export const UserPreferences = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "userPreferences", // a unique identifier for this form
            enableReinitialize: true,
            validate: userValidation,
        })(UserFormPresentation)
    )
)
