export const constructRequestPayloads = (orders, valuesFromState) => {
    const ordersRateRequests = orders.map(order => {
        const location = valuesFromState.profile.locations.find(
            location => location._id == order.locationId
        )
        let origin =
            order.mode === "OUTBOUND"
                ? {
                      contact: {
                          name:
                              valuesFromState.profile.firstname +
                              " " +
                              valuesFromState.profile.lastname,
                          phone: valuesFromState.profile.phone_number,
                          email: valuesFromState.profile.email,
                      },
                      pickupContact: location.pickupContact,
                      shippingAddress: location.shippingAddress,
                  }
                : {
                      contact: order.origin.contact,
                      shippingAddress: {
                          address: order.origin.address,
                          name: order.origin.name,
                      },
                  }
        let destination =
            order.mode === "INBOUND"
                ? {
                      contact: {
                          name:
                              valuesFromState.profile.firstname +
                              " " +
                              valuesFromState.profile.lastname,
                          phone: valuesFromState.profile.phone_number,
                          email: valuesFromState.profile.email,
                      },
                      pickupContact: location.pickupContact,
                      shippingAddress: location.shippingAddress,
                  }
                : {
                      contact: order.destination.contact,
                      shippingAddress: {
                          address: order.destination.address,
                          name: order.destination.name,
                      },
                  }

        return {
            locationId: order.locationId,
            cpg: order.cpg,
            preferredCurrencyCode: order.preferredCurrencyCode,
            preferredSystemOfMeasurement: order.preferredSystemOfMeasurement,
            pickupDate: order.pickupInformation.pickupDate,
            isInBondShipment: order.isInBondShipment,
            includeThirdParty: valuesFromState.search.includeThirdParty,
            userProvidedTotalLinearFeet:
                valuesFromState.search.userProvidedTotalLinearFeet,
            totalLinearFeet: valuesFromState.search.totalLinearFeet,
            capLoadTotalLinearFeet:
                valuesFromState.search.capLoadTotalLinearFeet,
            volumeTotalLinearFeet: valuesFromState.search.volumeTotalLinearFeet,
            isOverLTLLimit: valuesFromState.search.isOverLTLLimit,
            origin,
            destination,
            identifiers: { orderNumber: order.orderNumber },
            mode: order.mode.toLowerCase(),
            handlingUnits: order.handlingUnits,
            pickupAccessorials: order.origin.accessorials
                ? order.origin.accessorials
                : ["DOCKPU"],
            deliveryAccessorials: order.destination.accessorials
                ? order.destination.accessorials
                : ["DOCKDEL"],
            isFreightDirectReturn: false,
            isFreightDirect: false,
            associatedOrder: order.id,
            paymentType:
                order?.paymentType ??
                (location?.fedexFreightAccount &&
                location?.locationType !== "FEDEX_DIRECT" &&
                location?.locationType !== "FEDEX_DIRECT_RETURNS"
                    ? order.mode.toUpperCase() === "INBOUND"
                        ? "COLLECT"
                        : "PREPAID"
                    : null),
        }
    })
    return ordersRateRequests
}
