import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { reduxForm, reset } from "redux-form"
import { animateScroll } from "react-scroll"
import {
    Grid,
    withStyles,
    Button,
    CircularProgress,
    Typography,
    Snackbar,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import FlashMessage from "../flash-message"
import {
    changeStarredActiveShipment,
    populateTrackForm,
} from "../../../actions/track"
import {
    selectShipment,
    updateAlertsPreferences,
} from "../../../actions/book-shipment"
import { Card, CardHeader, IconButton } from "@material-ui/core"
import HeaderCard from "./headerCard"
import PickupCard from "./pickupCard"
import DeliveryCard from "./deliveryCard"
import AddressCard from "../../util/addressCard"
import { copyShipment } from "../../../actions/quote-request"
import HandlingUnitTimeline from "../../shipmentTimeline/handlingUnitTimeline"
import MasterTimeline from "../../shipmentTimeline/masterTimeline"
import { changePath } from "../../../actions"
import AlertsPreferences from "../../alerts/alertsPreferences"
import AlertBox from "../../alerts/alertBox"
import green from "@material-ui/core/colors/green"
import { ErrorSnackbarContentWrapper } from "../../errorSnackbar"
import { dismissAlert, fetchAlerts } from "../../../actions/alerts"
import WarningIcon from "@material-ui/icons/Warning"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import Avatar from "@material-ui/core/Avatar"
import AttachmentsCard from "./attachments/attachmentsCard"
import HandlingUnitsDisplay from "./handlingUnitsDisplay"
import { statusStepSelector } from "../../shipmentTimeline/masterTimeline"
import { useAttachmentsContext } from "../../../context/providers/attachmentsProvider"
import { useBookedShipmentContext } from "../../../context/providers/BookedShipmentProvider"
import {
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isValidCountryForFedexShipmentInPR,
} from "../../../actions/validation"
import TradeDocumentsCard from "./attachments/TradeDocumentsCard"
import GlobalSpinner from "../../common/GlobalSpinner"
import { isFedExCarrier } from "../../../misc"
import { weightUnit } from "../../util/units"
import { trackGAEvent } from "../../../actions/user"
import { goFetch } from "../../../http"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { JAX, SJU } from "../../../actions/util/domesticOffshoreTerminal"
import { useFlags } from "launchdarkly-react-client-sdk"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../../util/carrierLogoUtils"
import { useGAContext } from "../../../context/providers/GoogleAnalyticsProvider"
import { isOffshorePRShipmentFedexValid } from "../../util/offshoreUtils."

const parseContact = contact => ({
    ...contact,
    phone: contact.phone,
    email: contact.email.email_address,
})
const parseAddress = address => ({
    ...address,
    contact: parseContact(address.contact),
})

const styles = theme => ({
    parent__container: {
        margin: "2% 3%",
    },
    details__container: {
        margin: "1% 0%",
        minWidth: "1024px",
    },
    button__left: {
        marginLeft: theme.spacing(1),
    },
    details__content: {
        marginTop: "10px",
        padding: theme.spacing(3),
        "min-width": "870px",
    },
    carrier__logo: {
        height: "41px",
        width: "71px",
    },
    map__container: {
        "min-height": "350px",
    },
    navigation__buttons: {
        width: "70px",
        height: "70px",
    },
    shipment__item__column: {
        padding: "0 10px",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "auto",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    items_paper: {
        height: "100%",
    },
    items__divider: {
        "border-top": "1px solid black",
        "margin-top": "auto",
        "margin-left": "16px",
        "margin-right": "16px",
    },
    payment_detail: {
        width: "100%",
    },
    progress: {
        margin: theme.spacing(2),
        top: "50%",
        right: "50%",
        position: "absolute",
        zIndex: 9999,
    },
    destination_column: {
        paddingLeft: "20px",
    },
    destination_card: {
        paddingBottom: "20px",
    },
    origin_card: {
        paddingBottom: "20px",
    },
    timeline_column: {
        height: "100%",
    },
    chat: {
        paddingRight: "10px",
    },
    alerts__card: {
        padding: "15px 30px",
        width: "100%",
    },
    alerts__card__container: {
        width: "100%",
    },
    snackBar: {
        backgroundColor: green[600],
    },
    handlingUnitTimeline: {
        paddingBottom: "15px",
    },
    handlingUnitItemDetail: {
        paddingRight: "12px",
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: "16px",
    },
    handlingUnitProNumber: {
        textDecoration: "underline",
    },
    masterTimeline__container: {
        paddingBottom: "20px",
    },
    alertsAvatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    trackingAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    starAvatar: {
        margin: "5px",
        backgroundColor: "#fff",
    },
})

function ModalOpener({ shipmentId }) {
    const { openModal, shouldOpen } = useBookedShipmentContext()

    if (shouldOpen) {
        openModal(shipmentId)
    }

    return null
}

const IndividualHandlingUnitTimeline = withStyles(styles)(
    ({ classes, shipment, handlingUnit, query, index, shipmentId }) => (
        <Grid item container className={classes.handlingUnitTimeline}>
            <Grid item container alignItems="center">
                <Grid item container xs={3} alignItems="center">
                    {index === 0 ? (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="tracking__masterTrackingID"
                                defaultMessage="Master Tracking ID"
                            />
                            {" #:"}
                        </Typography>
                    ) : (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="tracking__trackingID"
                                defaultMessage="Tracking ID"
                            />
                            {" #:"}
                        </Typography>
                    )}
                    <span>&nbsp;</span>
                    <Typography
                        variant="subtitle1"
                        className={classes.handlingUnitProNumber}
                    >
                        {handlingUnit.proNumber}
                    </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item container xs={6} alignItems="center">
                    {handlingUnit.items?.map(item => (
                        <Grid item container>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >
                                {item.description}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >{`Pieces: ${item.pieces}`}</Typography>
                            <Typography
                                variant="caption"
                                className={classes.handlingUnitItemDetail}
                            >
                                <FormattedMessage
                                    id="items__itemWeight"
                                    defaultMessage="Item Weight"
                                />
                                {": "}
                                {Math.ceil(item.weight)}{" "}
                                {weightUnit(
                                    query?.preferredSystemOfMeasurement
                                )}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    item
                    container
                    xs={2}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Typography variant="caption">
                        <FormattedMessage
                            id="items__HUWeight"
                            defaultMessage="H/U Weight"
                        />
                        {`: ${handlingUnit?.handlingUnitWeight} ${weightUnit(
                            query?.preferredSystemOfMeasurement
                        )}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <HandlingUnitTimeline
                    status={handlingUnit?.status}
                    shipment={shipment}
                    shipmentId={shipmentId}
                    pickupDate={query?.pickupDate}
                />
            </Grid>
        </Grid>
    )
)

const TrackResultPresentation = ({
    identifiers,
    shipment,
    flashMessage,
    shipmentId,
    masterStatus,
    classes,
    shipAgain,
    onViewQuote,
    updateAlertsPreferences,
    handleSubmit,
    submitting,
    alerts,
    dismissAlert,
    fetchAlerts,
    loadShipment,
    list,
    canViewRateAmounts,
    handlingUnits,
    statusSteps,
    totalWeight,
    userId,
    owner,
    shipmentDetailsFormValues,
    isIntl,
    isUSDomesticOffshoreWithItemCustoms,
    history,
    query,
    flatHandlingUnits,
    locationId,
    shipmentLocation,
    trackGA,
    submitSucceeded,
    changeStarredValue,
    gaCategory,
}) => {
    const [state, setState] = useState({
        sbOpen: false,
        sbVariant: "",
        sbMessage: "",
        isLoading: false,
    })

    const setSnackbar = (sbVariant, sbMessage) => {
        setState(prevState => ({
            ...prevState,
            sbOpen: true,
            sbVariant,
            sbMessage,
        }))
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }

        setState(prevState => ({
            ...prevState,
            sbOpen: false,
        }))
    }

    useEffect(() => {
        animateScroll.scrollToTop({ duration: 200, smooth: true })
    }, [])

    useEffect(() => {
        if (submitSucceeded) {
            setState(prevState => ({
                ...prevState,
                sbOpen: true,
                sbMessage: "Successfully updated alerts",
                sbVariant: "success",
            }))
        }
    }, [submitSucceeded])

    const { origin, destination } = shipment || {}
    const shipmentReady = !!shipment
    const isShipmentCreator = true

    const { isLoading } = state

    const {
        isQuickRate,
        isQuickQuote,
        queryVersion,
        pickupDate,
        preferredSystemOfMeasurement,
    } = query || {}

    const { openSnackbar } = useSnackbarContext()

    const setStarred = async starredValue => {
        trackGA(gaCategory, "Star/Unstar Shipment Click")
        try {
            await goFetch(
                `/shipments/${shipmentId}/favorite`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        isFavorite: starredValue,
                    },
                },
                true
            )
            changeStarredValue(starredValue)
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="dashboard.starredError"
                    defaultMessage="Unable to star shipment"
                />
            )
        }
    }

    const isIntraMexico =
        shipment?.origin?.address?.country === "MX" &&
        shipment?.destination?.address?.country === "MX"

    const isOriginPRShipment = shipment?.origin?.address?.state === "PR"
    const isDestinationPRShipment =
        shipment?.destination?.address?.state === "PR"

    const isPRShipmentFedexValid = isOffshorePRShipmentFedexValid(
        shipment?.origin?.address?.country,
        shipment?.origin?.address?.state,
        shipment?.destination?.address?.country,
        shipment?.destination?.address?.state
    )

    const { domesticOffshoreRating } = useFlags()

    return (
        <div className={classes.parent__container}>
            {shipmentReady && <ModalOpener shipmentId={shipmentId} />}
            {isLoading && <GlobalSpinner />}
            <div className={classes.details__container}>
                <Grid container>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-end"
                        justify="center"
                        xs={1}
                    ></Grid>
                    {!shipmentReady && (
                        <CircularProgress
                            className={classes.progress}
                            size={50}
                            color="secondary"
                        />
                    )}
                    {flashMessage && (
                        <FlashMessage
                            shipmentConfirmationNumber={
                                identifiers.pickupNumber
                            }
                            proNumber={identifiers.proNumber}
                            pickupLater={!origin.startTime && !origin.endTime}
                        />
                    )}
                    <Grid item container xs={10} spacing={3}>
                        {shipmentReady && (
                            <Fragment>
                                <Grid
                                    item
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Grid item container xs={6}>
                                        <Grid item>
                                            <Avatar
                                                className={classes.starAvatar}
                                            >
                                                <IconButton
                                                    onClick={e => {
                                                        setStarred(
                                                            !shipment?.isFavorite
                                                        )
                                                        e.stopPropagation()
                                                    }}
                                                >
                                                    {shipment?.isFavorite ? (
                                                        <StarIcon color="primary" />
                                                    ) : (
                                                        <StarBorderIcon color="primary" />
                                                    )}
                                                </IconButton>
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        justify="flex-end"
                                    >
                                        {shipment?.rate?.mode !==
                                            "LTL_DIRECT_RETURNS" && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    disabled={isLoading}
                                                    onClick={async () => {
                                                        trackGA(
                                                            gaCategory,
                                                            "Ship Again Clicked"
                                                        )
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            isLoading: true,
                                                        }))
                                                        await shipAgain({
                                                            shipmentId,
                                                            queryVersion,
                                                            openSnackbar,
                                                        })
                                                        setState(prevState => ({
                                                            ...prevState,
                                                            isLoading: false,
                                                        }))
                                                    }}
                                                >
                                                    <FormattedMessage
                                                        id="dashboard.shipment__shipAgain"
                                                        defaultMessage="Ship Again"
                                                    />
                                                </Button>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                className={classes.button__left}
                                                onClick={() => {
                                                    trackGA(
                                                        gaCategory,
                                                        "RATES Clicked"
                                                    )
                                                    onViewQuote(
                                                        shipmentId,
                                                        isQuickQuote,
                                                        isQuickRate,
                                                        queryVersion
                                                    )
                                                }}
                                                disabled={isLoading}
                                            >
                                                <FormattedMessage
                                                    id="documents__rates"
                                                    defaultMessage="Rates"
                                                />
                                            </Button>
                                        </Grid>
                                        <FunctionalShareButton
                                            classes={classes}
                                            shipmentId={shipmentId}
                                            isLoading={isLoading}
                                            gaCategory={gaCategory}
                                        />
                                    </Grid>
                                </Grid>
                                <HeaderCard
                                    identifiers={identifiers}
                                    carrierCode={shipment.carrier}
                                    shipment={shipment}
                                    canViewRateAmounts={canViewRateAmounts}
                                    shipmentId={shipmentId}
                                    handlingUnits={handlingUnits}
                                    gaCategory={gaCategory}
                                />
                                {!!alerts?.events?.filter(
                                    event => event.type === "warning"
                                )?.length && (
                                    <Grid item container>
                                        <Card className={classes.alerts__card}>
                                            <CardHeader
                                                title={
                                                    <Typography variant="h6">
                                                        <FormattedMessage
                                                            id="orderDetails.alerts"
                                                            defaultMessage="Alerts"
                                                        />
                                                    </Typography>
                                                }
                                                avatar={
                                                    <Avatar
                                                        className={
                                                            classes.alertsAvatar
                                                        }
                                                    >
                                                        <WarningIcon />
                                                    </Avatar>
                                                }
                                            />
                                            <Grid item container xs={12}>
                                                {alerts?.events?.map(
                                                    (event, index) => {
                                                        if (
                                                            event.type ===
                                                            "warning"
                                                        ) {
                                                            return (
                                                                <AlertBox
                                                                    key={index}
                                                                    event={
                                                                        event
                                                                    }
                                                                    dismissAlert={
                                                                        dismissAlert
                                                                    }
                                                                    fetchAlerts={
                                                                        fetchAlerts
                                                                    }
                                                                    fetchShipment={
                                                                        loadShipment
                                                                    }
                                                                    shipmentId={
                                                                        shipmentId
                                                                    }
                                                                    proNumber={
                                                                        event.proNumber
                                                                    }
                                                                    gaCategory={
                                                                        gaCategory
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        return null
                                                    }
                                                )}
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )}
                                <Grid item container direction="row">
                                    <Card className={classes.alerts__card}>
                                        <CardHeader
                                            title={
                                                <Typography variant="h6">
                                                    <FormattedMessage
                                                        id="orderDetails.tracking"
                                                        defaultMessage="Tracking"
                                                    />
                                                </Typography>
                                            }
                                            avatar={
                                                <Avatar
                                                    className={
                                                        classes.trackingAvatar
                                                    }
                                                >
                                                    <LocalShippingIcon />
                                                </Avatar>
                                            }
                                        />
                                        <Grid
                                            item
                                            container
                                            className={
                                                classes.masterTimeline__container
                                            }
                                        >
                                            <MasterTimeline
                                                totalWeight={totalWeight}
                                                handlingUnits={handlingUnits}
                                                shipment={shipment}
                                                pickupDate={pickupDate}
                                                preferredSystemOfMeasurement={
                                                    preferredSystemOfMeasurement
                                                }
                                                shipmentId={shipmentId}
                                                identifiers={identifiers}
                                            />
                                        </Grid>
                                        {flatHandlingUnits.length > 1 &&
                                            isFedExCarrier(
                                                shipment?.rate?.carrierCode
                                            ) &&
                                            flatHandlingUnits.map(
                                                (handlingUnit, index) => (
                                                    <IndividualHandlingUnitTimeline
                                                        shipment={shipment}
                                                        shipmentId={shipmentId}
                                                        handlingUnit={
                                                            handlingUnit
                                                        }
                                                        key={index}
                                                        index={index}
                                                        query={query}
                                                    />
                                                )
                                            )}
                                    </Card>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        xs={6}
                                    >
                                        <Grid
                                            item
                                            container
                                            className={classes.origin_card}
                                        >
                                            <AddressCard
                                                fixed
                                                gridSize={12}
                                                address={parseAddress(origin)}
                                                title={
                                                    <FormattedMessage
                                                        id="generalTerms__origin"
                                                        defaultMessage="Origin"
                                                    />
                                                }
                                                visualVerification
                                            />
                                        </Grid>
                                        <Grid item container>
                                            <PickupCard
                                                pickupLater={
                                                    shipment?.isPickupLater
                                                }
                                                readyTime={origin.startTime}
                                                closeTime={origin.endTime}
                                                contact={
                                                    origin.appointment &&
                                                    parseContact(
                                                        origin.appointment
                                                    )
                                                }
                                                note={origin.note}
                                                list={list}
                                                status={masterStatus}
                                                pickupDate={pickupDate}
                                                pickedUp={
                                                    ![
                                                        "DISPATCHED",
                                                        "CREATED",
                                                    ].includes(
                                                        masterStatus?.currentStatus
                                                    )
                                                }
                                                shipmentId={shipmentId}
                                                terminal={
                                                    isPRShipmentFedexValid &&
                                                    isOriginPRShipment &&
                                                    domesticOffshoreRating &&
                                                    (containsCombinedOtherRatesAndFedexOceanRates(
                                                        shipment?.rate
                                                    ) ||
                                                        isFedExCarrier(
                                                            shipment?.rate
                                                                ?.carrierCode
                                                        ))
                                                        ? SJU
                                                        : shipment?.rate
                                                              ?.terminal
                                                }
                                                shipment={shipment}
                                                gridSize={12}
                                                isInBondShipment={
                                                    shipment?.isInBondShipment
                                                }
                                                isIntraMexico={isIntraMexico}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="column"
                                        xs={6}
                                        className={classes.destination_column}
                                    >
                                        <Grid
                                            item
                                            container
                                            className={classes.destination_card}
                                        >
                                            <AddressCard
                                                fixed
                                                gridSize={12}
                                                address={parseAddress(
                                                    destination
                                                )}
                                                title={
                                                    <FormattedMessage
                                                        id="generalTerms__destination"
                                                        defaultMessage="Destination"
                                                    />
                                                }
                                                visualVerification
                                                hideEmail={
                                                    shipment?.rate?.serviceLevel
                                                        ?.code ===
                                                        "BASIC_PICKUP_AND_DESTROY" &&
                                                    shipment?.rate
                                                        ?.rateQuoteDetail
                                                }
                                            />
                                        </Grid>
                                        <Grid item container>
                                            <DeliveryCard
                                                list={list}
                                                note={destination.note}
                                                gridSize={12}
                                                shipmentId={shipmentId}
                                                shipment={shipment}
                                                isInBondShipment={
                                                    shipment?.isInBondShipment
                                                }
                                                terminal={
                                                    isPRShipmentFedexValid &&
                                                    isDestinationPRShipment &&
                                                    domesticOffshoreRating &&
                                                    (containsCombinedOtherRatesAndFedexOceanRates(
                                                        shipment?.rate
                                                    ) ||
                                                        isFedExCarrier(
                                                            shipment?.rate
                                                                ?.carrierCode
                                                        ))
                                                        ? JAX
                                                        : shipment?.rate
                                                              ?.destinationTerminal
                                                }
                                                isIntraMexico={isIntraMexico}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <HandlingUnitsDisplay
                                        preferredSystemOfMeasurement={
                                            preferredSystemOfMeasurement
                                        }
                                        handlingUnits={handlingUnits}
                                    />
                                </Grid>

                                <Grid item container>
                                    <AttachmentsCard
                                        locationId={locationId}
                                        internalTrackingNumber={
                                            identifiers.internalTrackingNumber
                                        }
                                        setSnackbar={setSnackbar}
                                        isInternational={
                                            isIntl ||
                                            isUSDomesticOffshoreWithItemCustoms
                                        }
                                        gaCategory={gaCategory}
                                        isUSPRDomesticOffshore={
                                            isPRShipmentFedexValid && !isIntl
                                        }
                                    />
                                </Grid>

                                {(isIntl ||
                                    isUSDomesticOffshoreWithItemCustoms) &&
                                    (shipment?.carrier === "FXFE" ||
                                        shipment?.carrier === "FXNL") && (
                                        <Grid item container>
                                            <TradeDocumentsCard
                                                history={history}
                                                shipmentId={shipmentId}
                                                isUSPRDomesticOffshore={
                                                    isPRShipmentFedexValid &&
                                                    !isIntl
                                                }
                                            />
                                        </Grid>
                                    )}

                                <Grid item container>
                                    <form
                                        onSubmit={handleSubmit(
                                            updateAlertsPreferences
                                        )}
                                        className={
                                            classes.alerts__card__container
                                        }
                                    >
                                        <Card className={classes.alerts__card}>
                                            <AlertsPreferences
                                                share
                                                formName="shipmentDetails"
                                                isShipmentCreator={
                                                    isShipmentCreator
                                                }
                                                isPickupLater={
                                                    shipment.isPickupLater
                                                }
                                                isFedExShipment={
                                                    !!isFedExCarrier(
                                                        shipment?.rate
                                                            ?.carrierCode
                                                    )
                                                }
                                                isTrackPage
                                                statusSteps={statusSteps}
                                                isFreightDirect={
                                                    shipment?.rate?.mode ===
                                                        "LTL_DIRECT" ||
                                                    shipment?.rate?.mode ===
                                                        "LTL_DIRECT_RETURNS"
                                                }
                                                shipmentDetailsFormValues={
                                                    shipmentDetailsFormValues
                                                }
                                                location={shipmentLocation}
                                                gaCategory={gaCategory}
                                            />
                                            <Grid
                                                item
                                                container
                                                justify="flex-end"
                                            >
                                                {submitting && (
                                                    <CircularProgress
                                                        className={
                                                            classes.progress
                                                        }
                                                        size={50}
                                                        color="secondary"
                                                    />
                                                )}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    disabled={
                                                        masterStatus
                                                            ?.currentStatus
                                                            ?.code ===
                                                        "DELIVERED"
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="generalTerms__update"
                                                        defaultMessage="Update"
                                                    />
                                                </Button>
                                            </Grid>
                                            <Snackbar
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                                open={state.sbOpen}
                                                autoHideDuration={6000}
                                                onClose={handleSnackbarClose}
                                            >
                                                <ErrorSnackbarContentWrapper
                                                    variant={state.sbVariant}
                                                    onClose={
                                                        handleSnackbarClose
                                                    }
                                                    message={
                                                        <span>
                                                            <Typography variant="body2">
                                                                {
                                                                    state.sbMessage
                                                                }
                                                            </Typography>
                                                        </span>
                                                    }
                                                />
                                            </Snackbar>
                                        </Card>
                                    </form>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        justify="center"
                        xs={1}
                    ></Grid>
                </Grid>
            </div>
        </div>
    )
}

const FunctionalShareButton = ({
    classes,
    shipmentId,
    isLoading,
    gaCategory,
}) => {
    const { openDialog } = useAttachmentsContext()
    const { logGAEvent } = useGAContext()
    return (
        <Grid item>
            <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.button__left}
                onClick={() => {
                    logGAEvent(gaCategory, "Share Button Click")
                    openDialog(
                        shipmentId,
                        "share",
                        undefined,
                        undefined,
                        true,
                        gaCategory
                    )
                }}
                disabled={isLoading}
            >
                <FormattedMessage
                    id="rateResults.share__share"
                    defaultMessage="Share"
                />
            </Button>
        </Grid>
    )
}

TrackResultPresentation.propTypes = {
    identifiers: PropTypes.object,
    shipment: PropTypes.object,
    shipmentCopying: PropTypes.bool,
    flashMessage: PropTypes.bool,
    pickupLater: PropTypes.bool.isRequired,
    selectShipment: PropTypes.func.isRequired,
    getShipmentIndex: PropTypes.func.isRequired,
    shipmentId: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
}

TrackResultPresentation.defaultProps = {
    identifiers: {},
    shipment: undefined,
    shipmentCopying: false,
    flashMessage: false,
    items: [],
}

const resultMapStateToProps = (state, props) => {
    const activeShipment =
        state?.shipment?.activeShipment ??
        state?.shipment?.list[props?.shipmentId]

    const alerts = {
        user: activeShipment?.alerts?.preferences,
    }
    const selectedRecipients = []

    for (const recipient of activeShipment?.alerts?.share ?? []) {
        alerts[recipient.name] = {
            ...recipient.preferences,
            language: recipient.language,
        }
        selectedRecipients.push({
            value: {
                type: "email",
                value: recipient.email,
            },
            label: recipient.name,
        })
    }

    const shipmentLocation = state?.user?.profile?.locations?.find(
        loc => loc.cpgCode === activeShipment?.query?.cpg
    )

    const isIntl = isInternationalShipment(
        activeShipment?.shipment?.origin?.address?.country,
        activeShipment?.shipment?.destination?.address?.country
    )

    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        activeShipment?.shipment?.origin?.address?.country,
        activeShipment?.shipment?.destination?.address?.country,
        activeShipment?.shipment?.origin?.address?.state,
        activeShipment?.shipment?.destination?.address?.state
    )

    let statusSteps = null
    if (activeShipment) {
        statusSteps = statusStepSelector(state, {
            ...props,
            ...activeShipment,
        })
    }

    const flatHandlingUnits = [].concat(
        ...(activeShipment?.handlingUnits?.map(
            hu =>
                hu?.details?.map(details => ({
                    ...hu,
                    ...details,
                })) ?? []
        ) ?? [])
    )

    const masterStatus = flatHandlingUnits?.[0]?.status

    return {
        ...activeShipment,
        identifiers: activeShipment?.identifiers ?? {},
        quote: activeShipment?.shipment?.rate,
        shipmentCopying: state.quotes.active.isFetching,
        flashMessage: state.shipment.flashMessage,
        initialValues: {
            selectedRecipients,
            alerts,
        },
        alertsPreferences: state?.user?.profile?.preferences?.alerts,
        canViewRateAmounts:
            shipmentLocation?.users?.[0]?.permissions?.viewRateAmounts?.value,
        statusSteps: statusSteps ?? {},
        userId: state?.user?.profile?.id,
        shipmentDetailsFormValues: state?.form?.shipmentDetails?.values,
        documentCategory:
            state?.form?.attachment?.values?.documentCategory ?? "",
        userFileName: state?.form?.attachment?.values?.userFileName ?? "",
        isIntl,
        isUSDomesticOffshoreWithItemCustoms,
        flatHandlingUnits,
        masterStatus,
        locationId: shipmentLocation?._id,
        shipmentLocation,
        pageSize: state?.shipment?.pagination?.pageSize,
        page: state?.shipment?.pagination?.page,
        dashboard: state?.dashboard,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    selectShipment: shipmentId => dispatch(selectShipment({ shipmentId })),
    shipAgain: async ({ queryVersion, openSnackbar, shipmentId }) =>
        await dispatch(
            copyShipment({
                shipmentId,
                queryVersion,
                shipAgain: true,
                openSnackbar,
            })
        ),
    onViewQuote: (bolNumber, isQuickQuote, isQuickRate, queryVersion) => {
        if (queryVersion === "V1" || !queryVersion) {
            dispatch(
                changePath(
                    isQuickQuote ? `/qrate/${bolNumber}` : `/rate/${bolNumber}`
                )
            )
        } else if (queryVersion === "V2") {
            dispatch(
                changePath(
                    isQuickRate
                        ? `/quickRate/${bolNumber}`
                        : `/book/${bolNumber}`
                )
            )
        }
    },
    updateAlertsPreferences: () => {
        dispatch(trackGAEvent(props.gaCategory, "Alert Preferences Update"))
        dispatch(updateAlertsPreferences(props.shipmentId, "shipmentDetails"))
    },
    dismissAlert: (shipmentId, alertId) =>
        dispatch(dismissAlert(shipmentId, alertId, true)),
    loadShipment: shipmentId => dispatch(populateTrackForm(shipmentId)),
    resetForm: () => dispatch(reset("attachment")),
    fetchAlerts: () => dispatch(fetchAlerts()),
    trackGA: (category, action, label) =>
        dispatch(trackGAEvent(category, action, label)),
    changeStarredValue: isFavorite =>
        dispatch(changeStarredActiveShipment(isFavorite)),
})

export default withStyles(styles)(
    connect(
        resultMapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "shipmentDetails", // a unique identifier for this form
            enableReinitialize: true,
        })(TrackResultPresentation)
    )
)
