import { defineMessages } from "react-intl"

export const freightBoxPackageTypesMessages = defineMessages({
    Standard: {
        id: "items.packageTypes__Standard",
        defaultMessage: "Standard 38",
    },
    Small: {
        id: "items.packageTypes__Small",
        defaultMessage: "Small 28",
    },
})

export const freightBoxPackageTypes = [
    {
        value: "FBXP",
        label: freightBoxPackageTypesMessages.Standard,
    },
    {
        value: "FBXI",
        label: freightBoxPackageTypesMessages.Small,
    },
]

export const providePackageTypes = intl => {
    return freightBoxPackageTypes.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}
