import { change, getFormValues, resetSection } from "redux-form"
import { typedError, uniqueRequest } from "./index"
import { goFetch } from "../http"
import { constructPostalCode, isValidCountryPostalCode } from "./validation"
import { get } from "lodash"

export const POSTAL_CODE_RESULT = "POSTAL_CODE_RESULT"
export const POSTAL_CODE_ERROR = "POSTAL_CODE_ERROR"
export const POSTAL_CODE_TIMEZONE = "POSTAL_CODE_TIMEZONE"

const postalCodeResult = (postalCode, result) => ({
    type: POSTAL_CODE_RESULT,
    postalCode,
    ...result,
})
const postalCodeError = (error, postalCode) =>
    typedError(POSTAL_CODE_ERROR, error, postalCode)

function lookupPostalCode(postcode, fetchTimezone, country) {
    return async dispatch => {
        const processData = async () => {
            const params = {
                queryString: postcode,
                timezone: fetchTimezone,
                country,
            }
            const { data, status } = await goFetch(
                "/lookup/location/city",
                {
                    validErrorCodes: [404],
                    params,
                },
                true
            )
            if (status === 404) {
                return dispatch(postalCodeResult(postcode, { country }))
            }
            return dispatch(postalCodeResult(postcode, { ...data, country }))
        }
        const id = `lookupPostalCode_${country}_${postcode}`
        return dispatch(uniqueRequest(id, processData))
    }
}

export function requestPostalCode(
    value,
    { form, field, country, fetchTimezone = false }
) {
    return async (dispatch, getState) => {
        try {
            let appState = getState()
            let formValues = form && getFormValues(form)(appState)
            const fieldPrefix = field
                ?.split(".")
                ?.slice(0, -1)
                ?.join(".")
            const stateField = fieldPrefix ? `${fieldPrefix}.state` : "state"
            const countryFieldPath = fieldPrefix
                ? `${fieldPrefix}.country`
                : "country"

            const postalCodeCountry =
                country ?? get(formValues, countryFieldPath)
            const postalCode = constructPostalCode(value.toString(), country)
            const cityField = fieldPrefix ? `${fieldPrefix}.city` : "city"
            if (!isValidCountryPostalCode(postalCode, postalCodeCountry)) {
                if (form) {
                    dispatch(resetSection(form, stateField))
                }
                return {}
            }

            const key = `${postalCodeCountry}-${postalCode}`

            const { cities, state, timezone } = getState().postalCode[key] || {}
            if (!cities || !state || (fetchTimezone && !timezone)) {
                await dispatch(
                    lookupPostalCode(
                        postalCode,
                        fetchTimezone,
                        postalCodeCountry
                    )
                )
            }
            const result = getState().postalCode[key] || {}
            if (form) {
                if (result.cities && result.state) {
                    dispatch(change(form, stateField, result.state))
                    dispatch(change(form, cityField, result.cities[0]))
                }
                if (fetchTimezone && result.timezone) {
                    dispatch(change(form, `${field}Timezone`, result.timezone))
                }
            }
            return result
        } catch (error) {
            dispatch(postalCodeError(error, value || ""))
        }
    }
}

export function requestPostalCodeQuickRate(
    value,
    { form, field, country, fetchTimezone = false }
) {
    return async (dispatch, getState) => {
        try {
            const appState = getState()
            const formValues = form && getFormValues(form)(appState)
            const fieldPrefix = field
                ?.split(".")
                ?.slice(0, -1)
                ?.join(".")
            const stateField = fieldPrefix ? `${fieldPrefix}.state` : "state"
            const countryFieldPath = fieldPrefix
                ? `${fieldPrefix}.country`
                : "country"

            const postalCodeCountry =
                country ?? get(formValues, countryFieldPath)
            const postalCode = constructPostalCode(value.toString(), country)
            const cityField = fieldPrefix ? `${fieldPrefix}.city` : "city"
            if (!isValidCountryPostalCode(postalCode, postalCodeCountry)) {
                if (form) {
                    dispatch(resetSection(form, stateField))
                }
                return {}
            }
            const key = `${postalCodeCountry}-${postalCode}`
            const { cities, state, timezone } = getState().postalCode[key] || {}
            if (!cities || !state || (fetchTimezone && !timezone)) {
                await dispatch(
                    lookupPostalCode(
                        postalCode,
                        fetchTimezone,
                        postalCodeCountry
                    )
                )
            }
            const result = getState().postalCode[key] || {}
            if (form) {
                if (result.cities && result.state) {
                    dispatch(change(form, stateField, result.state))
                    dispatch(change(form, cityField, result.cities[0]))
                }
                if (fetchTimezone && result.timezone) {
                    dispatch(change(form, `${field}Timezone`, result.timezone))
                }
            }
            return result
        } catch (error) {
            dispatch(postalCodeError(error, value || ""))
        }
    }
}
