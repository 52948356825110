import React, { useEffect } from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { Grid, Typography, withStyles } from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"
import DeleteIcon from "@material-ui/icons/Delete"
import { Field, formValues } from "redux-form"
import FormField from "../../../form/form-field"
import { providePackageTypes } from "../../../item/FreightBox-line-item"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormSwitch from "../../../form/form-switch"
import { join } from "../../../../misc"
import { connect } from "react-redux"
import { itemSearchHash } from "../../../../actions/item"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline",
    },
    expansionPanel: {
        width: "100%",
    },
    item__icon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    switchesContainer: {
        paddingLeft: "30px",
    },
    itemsContainer: {
        paddingTop: "15px",
    },
})

export const composeFreightBoxHandlingUnitSummary = (hu, weightUnit, intl) => {
    const { count, isIndividualHUWeight, isMultiClass, packageType, items } = hu

    const huCount = intl.formatMessage(
        {
            id: "items.handlingUnit__huCount",
            defaultMessage: "H/U Count: {count}",
        },
        { count }
    )

    const huInfo = [packageType, huCount].filter(x => x).join(", ")

    const itemInfo = items
        .map(item => {
            const itemDescription = item.description
                ? `${item.description}`
                : ""
            let itemWeight
            if (isIndividualHUWeight || isMultiClass) {
                itemWeight = item.weight
                    ? `${item.weight * count}${weightUnit}`
                    : ""
            } else {
                itemWeight = item.weight ? `${item.weight}${weightUnit}` : ""
            }

            const itemClass = item?.freightClass
                ? intl.formatMessage(
                      {
                          id: "items.handlingUnit__class",
                          defaultMessage: "Class {class}",
                      },
                      { class: item?.freightClass }
                  )
                : ""
            return [itemDescription, itemWeight, itemClass]
                .filter(x => x)
                .join(", ")
        })
        .join("  |  ")

    return [itemInfo, huInfo].filter(x => x).join("  -  ")
}

const FreightBoxHandlingUnitContainer = ({
    isOpen,
    handlePanel,
    onRemoveClick,
    classes,
    prefix,
    formValues = {},
    changeField,
    index,
    items = [],
    count = 1,
    weightUnit,
    lengthUnit,
    adjustEstimatedLinearFeet,
    adjustEstimatedLinearFeetOnSearchChange,
    isMultiClass,
    intl,
    packageType,
    isIntl,
    isIndividualHUWeight,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    hu,
    trackGA,
    gaCategory,
    isIntraMexico,
    isUSDomesticOffshoreWithItemCustoms,
}) => {
    useEffect(() => {
        if (isMultiClass) {
            const result = items?.reduce((acc, item) => {
                if (item.weight) {
                    return (acc += Number(item?.weight))
                } else return acc
            }, 0)
            const finalResult = isIndividualHUWeight
                ? result
                : Number(result * count)
            changeField(`${prefix}.totalHUWeight`, finalResult ?? 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMultiClass, isIndividualHUWeight, items, count])

    const handlePackageType = value => {
        if (value === "FBXP") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 40)
            changeField(`${prefix}.height`, 38)
        } else if (value === "FBXI") {
            changeField(`${prefix}.length`, 48)
            changeField(`${prefix}.width`, 40)
            changeField(`${prefix}.height`, 28)
        }
    }

    const onIsIndividualHUWeightChange = value => {
        trackGA("Book Shipment - Items", "IndividualHUWeight Change", value)
    }

    return (
        <ExpansionPanel
            expanded={isOpen}
            onChange={(_, open) => handlePanel(open)}
            className={classes.expansionPanel}
        >
            <ExpansionPanelSummary
                expandIcon={<ExpandMore id={`${prefix}.expandMoreIcon`} />}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid container item alignItems="center" xs={9}>
                        {!isOpen && (
                            <Typography variant="caption">
                                {composeFreightBoxHandlingUnitSummary(
                                    hu,
                                    weightUnit,
                                    intl
                                )}
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={3}
                        alignItems="center"
                        justify="flex-end"
                    >
                        <DeleteIcon
                            id={`${prefix}.removeIcon`}
                            onClick={e => onRemoveClick(e)}
                            className={classes.item__icon}
                        />
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid item container>
                    <Grid item container>
                        <Grid item container>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="bookShipment.items__freightBoxHandlingUnitInfo"
                                    defaultMessage="Freight Box Information"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container xs={9}>
                            <Grid item xs={6}>
                                <Field
                                    component={FormSelectAutocomplete}
                                    name={`${prefix}.packageType`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__packageType"
                                            defaultMessage="Package Type"
                                        />
                                    }
                                    required
                                    options={providePackageTypes(intl)}
                                    onChange={(e, value) =>
                                        handlePackageType(value)
                                    }
                                />
                            </Grid>
                            <Grid item container xs={3}>
                                <Field
                                    component={FormField}
                                    name={`${prefix}.count`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__huCount"
                                            defaultMessage="H/U Count"
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        adjustEstimatedLinearFeet(
                                            prefix,
                                            "count",
                                            newValue
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item container xs={3} justify="center">
                                <Field
                                    component={FormField}
                                    name={`${prefix}.items[0].weight`}
                                    label={
                                        isIndividualHUWeight ? (
                                            <FormattedMessage
                                                id="getRates.items__individualWeight"
                                                defaultMessage="Weight per H/U ({weightUnit})"
                                                values={{
                                                    weightUnit,
                                                }}
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="getRates.items__totalWeight"
                                                defaultMessage="Total Weight ({weightUnit})"
                                                values={{
                                                    weightUnit,
                                                }}
                                            />
                                        )
                                    }
                                    required
                                    disabled={isMultiClass}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    component={FormField}
                                    name={`${prefix}.length`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__length"
                                            defaultMessage="Length ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        adjustEstimatedLinearFeet(
                                            prefix,
                                            "length",
                                            newValue
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    component={FormField}
                                    name={`${prefix}.width`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__width"
                                            defaultMessage="Width ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        adjustEstimatedLinearFeet(
                                            prefix,
                                            "width",
                                            newValue
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Field
                                    component={FormField}
                                    name={`${prefix}.height`}
                                    label={
                                        <FormattedMessage
                                            id="getRates.items__height"
                                            defaultMessage="Height ({lengthUnit})"
                                            values={{
                                                lengthUnit,
                                            }}
                                        />
                                    }
                                    required
                                    onChange={(e, newValue) => {
                                        adjustEstimatedLinearFeet(
                                            prefix,
                                            "height",
                                            newValue
                                        )
                                    }}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={3}
                            direction="column"
                            className={classes.switchesContainer}
                        >
                            <Grid item>
                                <Field
                                    component={FormSwitch}
                                    name={`${prefix}.isIndividualHUWeight`}
                                    label={
                                        <FormattedMessage
                                            id="bookShipment.items__weightPerHU"
                                            defaultMessage="Specify Weight per H/U"
                                        />
                                    }
                                    onChange={(e, newValue) =>
                                        onIsIndividualHUWeightChange(newValue)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.itemsContainer}
                            xs={9}
                        >
                            <Field
                                component={FormField}
                                name={`${prefix}.items[0].description`}
                                label={
                                    <FormattedMessage
                                        id="items__description"
                                        defaultMessage="Description"
                                    />
                                }
                                // onChange={e => doSearch(e)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const mapStateToProps = (
    state,
    { description, unNumber, origin, originAddress }
) => ({
    itemsDescription:
        state.item.search[itemSearchHash(description, origin, originAddress)],
    itemsUN: state.item.search[itemSearchHash(unNumber, origin, originAddress)],
    searchInProgress: state.item.searchInProgress,
})

const FreightBoxHandlingUnit = formValues(({ prefix }) => ({
    count: join(prefix, "count"),
    totalWeight: join(prefix, "totalWeight"),
    items: join(prefix, "items"),
    packageType: join(prefix, "packageType"),
    length: join(prefix, "length"),
    width: join(prefix, "width"),
    height: join(prefix, "height"),
    isMultiClass: join(prefix, "isMultiClass"),
    totalHUWeight: join(prefix, "totalHUWeight"),
    isIndividualHUWeight: join(prefix, "isIndividualHUWeight"),
    hu: prefix,
}))(connect(mapStateToProps)(injectIntl(FreightBoxHandlingUnitContainer)))

export default injectIntl(withStyles(styles)(FreightBoxHandlingUnit))
